// This file is responsible for setting up the Vue Router and defining the routes for the application.
// It also includes tenant-awareness, meaning that the routes are dynamically adjusted based on the tenant (organisation) the user belongs to.
import { createRouter, createWebHistory } from 'vue-router';
import { supabase } from './supabase';
import Dashboard from './views/view-dashboard.vue';
import Customers from './views/view-customers.vue';
import CustomerDetails from './views/view-customer-details.vue';
import Advisors from './views/view-advisors.vue';
import AdvisorDetails from './views/view-advisor-details.vue';
import Calls from './views/view-calls.vue';
import CallsDetails from './views/view-calls-details.vue';
import Documents from './views/view-documents.vue';
import DocumentDetails from './views/view-document-details.vue';
import ConversationTypes from './views/view-settings-conversation-types.vue';
import Scorecards from './views/view-scorecards.vue';
import ScorecardDetails from './views/view-scorecard-edit.vue';
import ScorecardDetailsNew from './views/view-scorecard-edit-new.vue';
import Documenttypes from './views/view-document-types.vue';
import Settings from './views/view-settings.vue';
import Auth from './views/view-auth.vue';
import GoogleCalendarOAuth from './views/view-google-calendar-oauth.vue';
import MicrosoftCalendarOAuth from './views/view-microsoft-calendar-oauth.vue';
import GeneralSettings from './views/view-settings-general.vue';
import Logout from './views/view-logout.vue';
import Login from './views/view-login.vue';
import Register from './views/view-register.vue';
import Confirmation from './views/view-confirmation.vue';
import NewOrg from './views/view-create-org.vue';
import Upload from './views/view-upload.vue';
import Reset from './views/view-password-reset.vue';
import UpdatePassword from './views/view-update-password.vue';
import AdvisorsAdd from '@/views/view-advisors-add.vue';
import ConversationTypeDetails from '@/views/view-conversationtype-details.vue';

const routes = [
  { path: '/:tenant/', name: 'dashboard', component: Dashboard, meta: { title: 'TODAY', requiresAuth: true, activeMenuItem: 'dashboard' } },
  { path: '/:tenant/calls', name: 'calls', component: Calls, meta: { requiresAuth: true, activeMenuItem: 'calls' } },
  { path: '/:tenant/calls/:id/:tab?', name: 'call details', component: CallsDetails, meta: { requiresAuth: true, activeMenuItem: 'calls' } },
  { path: '/:tenant/customers', name: 'customers', component: Customers, meta: { requiresAuth: true, activeMenuItem: 'customers' } },
  { path: '/:tenant/customers/:id', name: 'customer details', component: CustomerDetails, meta: { requiresAuth: true, activeMenuItem: 'customers' } },
  { path: '/:tenant/advisors', name: 'advisors', component: Advisors, meta: { requiresAuth: true, activeMenuItem: 'advisors' } },
  { path: '/:tenant/advisors/:id', name: 'advisor details', component: AdvisorDetails, meta: { requiresAuth: true, activeMenuItem: 'advisors' } },
  { path: '/:tenant/advisors/add', name: 'AdvisorsAdd', component: AdvisorsAdd, meta: { requiresAuth: true, routeguarded: false, activeMenuItem: 'advisors' } },
  { path: '/:tenant/documents', name: 'documents', component: Documents, meta: { requiresAuth: true, activeMenuItem: 'documents' } },
  { path: '/:tenant/documents/:id', name: 'document details', component: DocumentDetails, meta: { requiresAuth: true, activeMenuItem: 'documents' } },
  { path: '/:tenant/scorecards', name: 'scorecards', component: Scorecards, meta: { requiresAuth: true, routeguarded: false, activeMenuItem: 'settings' } },
  { path: '/:tenant/scorecards/:id', name: 'scorecard details', component: ScorecardDetails, meta: { requiresAuth: true, routeguarded: false, activeMenuItem: 'settings' } },
  { path: '/:tenant/scorecards-new/:id', name: 'scorecard details new', component: ScorecardDetailsNew, meta: { requiresAuth: true, routeguarded: false, activeMenuItem: 'settings' } },
  { path: '/:tenant/settings', name: 'settings', component: Settings, meta: { requiresAuth: true, routeguarded: false, activeMenuItem: 'settings' } },
  { path: '/:tenant/settings/general', name: 'general settings', component: GeneralSettings, meta: { requiresAuth: true, routeguarded: false, activeMenuItem: 'settings' } },
  { path: '/:tenant/settings/conversationtypes', name: 'conversation types', component: ConversationTypes, meta: { requiresAuth: true, routeguarded: false, activeMenuItem: 'settings' } },
  { path: '/:tenant/settings/conversationtypes/:type', name: 'ConversationTypeDetails', component: ConversationTypeDetails, meta: { requiresAuth: true, routeguarded: false, activeMenuItem: 'settings' } },
  { path: '/:tenant/settings/auth', name: 'auth', component: Auth, meta: { requiresAuth: true, routeguarded: false, activeMenuItem: 'settings' } },
  { path: '/oauth-callback/google-calendar', name: 'google calendar', component: GoogleCalendarOAuth, meta: { requiresAuth: false, routeguarded: false, activeMenuItem: 'settings' } },
  { path: '/oauth-callback/microsoft-calendar', name: 'microsoft calendar', component: MicrosoftCalendarOAuth, meta: { requiresAuth: false, routeguarded: false, activeMenuItem: 'settings' } },
  { path: '/:tenant/document-types', name: 'document types', component: Documenttypes, meta: { requiresAuth: true, activeMenuItem: 'settings' } },
  { path: '/logout', name: 'logout', component: Logout },
  { path: '/login', name: 'login', component: Login },
  { path: '/password-reset', name: 'password-reset', component: Reset },
  { path: '/update-password', name: 'update-password', component: UpdatePassword, activeMenuItem: 'documents' },
  { path: '/register', name: 'register', component: Register },
  { path: '/confirmation', name: 'confirmation', component: Confirmation },
  { path: '/new-org', name: 'new org', component: NewOrg },
  { path: '/:tenant/upload', name: 'upload', component: Upload, meta: { requiresAuth: true, activeMenuItem: 'upload' } },
  { path: '/:tenant/team-lead-overview', name: 'TeamLeadOverview', component: () => import('@/components/TeamLeadOverview.vue') },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let currentUser = null;
supabase.auth.onAuthStateChange((event, session) => {
  currentUser = session ? session.user : null;

  if (currentUser) {
    supabase
      .from('organisations')
      .select('name')
      .contains('associated_user_ids', [currentUser.id])
      .then(({ data: organisations, error }) => {
        if (error) {
          console.error('Error fetching tenant:', error);
        } else if (!organisations || organisations.length === 0) {
          router.push('/new-org');
        }
      });
  }
});

router.beforeEach(async (to, from, next) => {
  let originalRoute = from.fullPath;

  if (to.fullPath.startsWith('http://') || to.fullPath.startsWith('https://')) {
    window.location.href = to.fullPath;
    return;
  }

  document.title = to.meta?.title ?? 'TODAY';

  // CHeck if user is tryong to access the root URL
  const isRootRoute = to.path === '/';

  // Check if the route requires authentication
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  // Check if the user is logged in
  const isLoggedIn = currentUser && currentUser.id;

  // Redirect to login if the user is trying to access the root URL and is not logged in
  if (isRootRoute && !isLoggedIn) {
    console.log('User is not logged in, redirecting to login...');
    next('/login');
    return;
  }

  if (isLoggedIn && requiresAuth) {
    let tenant = null;
    const { data: organisations, error } = await supabase
      .from('organisations')
      .select('id, name')
      .contains('associated_user_ids', [currentUser.id]);

    if (error) {
      console.error('Error fetching tenant:', error);
      next('/login');
      return;
    }

    if (organisations && organisations.length > 0) {
      tenant = organisations[0].name;
      const tenantId = organisations[0].id;

      if (!to.params.tenant) {
        // No tenant in route params, setting tenant
        next({ ...to, params: { ...to.params, tenant: tenant } });
        return;
      }

      const isRouteGuarded = to.matched.some(record => record.meta.routeguarded !== false);
      const pathSegments = to.path.split('/').filter(Boolean);
      const resource = pathSegments[1];
      const resourceId = pathSegments[2];

      if (isRouteGuarded && resource && resourceId) {
        const { data: resourceData, error: resourceError } = await supabase
          .from(resource)
          .select('organisation')
          .eq('id', resourceId)
          .single();

        if (resourceError || !resourceData || resourceData.organisation !== tenantId) {
          next(originalRoute);
          return;
        }
      }

      if (to.params.tenant === tenant) {
        next();
      } else {
        next({ ...to, params: { ...to.params, tenant: tenant } });
        return;
      }
    } else {
      next('/new-org');
    }
  } else if (!isLoggedIn && requiresAuth) {
    next('/login');
  } else {
    next();
  }
});

export default router;