<template>
    <div>
        <v-card class="mb-4">
            <v-row no-gutters>
                <v-col cols="6" class="border-right">
                    <v-card-title>{{ $t('teamLead.time_period') }}</v-card-title>
                    <v-card-text>
                        <div class="mt-2">
                            {{ $t('teamLead.begin') }}: {{ formatDateDE(dateRange[0]) }} - {{ formatDateDE(dateRange[1])
                            }}
                        </div>
                        <vue-slider v-model="dateRange" :min="startDate" :max="endDate" :showTooltip="drag"
                            :tooltip-formatter="formatTooltipDate">
                        </vue-slider>
                    </v-card-text>
                </v-col>

                <v-col cols="6">
                    <v-card-title>Gesprächstyp</v-card-title>
                    <v-card-text>
                        <v-select :items="scorecards" v-model="selectedScorecard" item-title="name" label="Scorecard"
                            item-value="id" hide-details dense></v-select>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>

        <table>
            <thead>
                <tr>
                    <th>{{ $t('teamLead.chapter_name') }}</th>
                    <th v-for="advisor in advisors" :key="advisor.id">
                        {{ advisor.first_name || advisor.name }}
                    </th>
                    <th>{{ $t('teamLead.overall') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in tableRows" :key="index">
                    <td>{{ localizeChapterName(row.chapter_name) }}</td>
                    <td v-for="advisor in advisors" :key="advisor.id"
                        @click="navigateToCallsView(advisor, row.chapter_name, row[`advisor_${advisor.id}`])"
                        :class="{ 'clickable': row[`advisor_${advisor.id}`] !== null && row[`advisor_${advisor.id}`] !== undefined }"
                        :style="getPercentageStyle(row[`advisor_${advisor.id}`], row.chapter_name)">
                        {{ formatValue(row.chapter_name, row[`advisor_${advisor.id}`]) }}
                    </td>
                    <td :style="getPercentageStyle(calculateOverall(row), row.chapter_name)">{{
                        formatValue(row.chapter_name, calculateOverall(row)) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { supabase, userState } from "../supabase";
import 'vue-slider-component/theme/default.css';
import VueSlider from 'vue-slider-component/dist/vue-slider-component.umd.min.js';
import { useRouter } from 'vue-router';

export default {
    components: {
        VueSlider
    },
    setup() {
        const router = useRouter();
        return { router };
    },
    data() {
        const now = new Date();
        const twoMonthsAgo = new Date();
        twoMonthsAgo.setDate(now.getDate() - 60);
        const startDate = twoMonthsAgo.getTime();
        const endDate = now.getTime();
        const dateRange = [startDate, endDate];
        const formatTooltipDate = (value) => {
            if (Array.isArray(value)) {
                return value.map(timestamp =>
                    new Date(timestamp).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })
                ).join(' - ')
            }

            // Fallback for single value (though not used in range mode)
            return new Date(value).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        }

        return {
            scorecards: [],
            selectedScorecard: null,
            tableRows: [],
            advisors: [],
            startDate,
            endDate,
            dateRange,
            formatTooltipDate
        }
    },
    async created() {
        const orgId = await this.fetchOrganization();
        if (orgId) await this.fetchScorecards(orgId);
    },
    watch: {
        selectedScorecard(newVal) {
            if (newVal) this.fetchAllData();
        },
        dateRange() {
            if (this.selectedScorecard) this.fetchAllData();
        }
    },
    computed: {
        filteredTableRows() {
            const [startDate, endDate] = this.dateRange;

            return this.tableRows.filter(row => {
                const callStart = new Date(row.start).getTime();
                return callStart >= startDate && callStart <= endDate;
            });
        }
    },
    methods: {
        async fetchOrganization() {
            const user = userState.currentUser;
            if (!user) {
                console.error("User not found");
                return;
            }

            const { data: orgData, error } = await supabase
                .from("organisations")
                .select("id")
                .contains("associated_user_ids", [user.id])
                .single();

            return error ? null : orgData.id;
        },
        async fetchScorecards(orgId) {
            const { data: scorecards, error } = await supabase
                .from("scorecards")
                .select("id, name")
                .eq("organisation", orgId)
                .order('id', { ascending: true });

            if (error) {
                console.error("Error fetching scorecards:", error);
                return;
            }

            this.scorecards = scorecards.map(scorecard => ({
                id: scorecard.id,
                name: scorecard.name || 'Unnamed Scorecard'
            }));

            if (this.scorecards.length > 0) {
                this.selectedScorecard = this.scorecards[0].id;
            }
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
        formatTooltip(value) {
            return new Date(value).toISOString().split('T')[0];
        },
        formatDateISO(timestamp) {
            return new Date(timestamp).toISOString();
        },

        async fetchAllData() {
            const user = userState.currentUser;
            if (!user) throw new Error('User not found');
            const startDate = this.formatDateISO(this.dateRange[0]);
            const endDate = this.formatDateISO(this.dateRange[1]);
            const response = await fetch(`/api/combinedinfo?user_id=${user.id}&scorecard_id=${this.selectedScorecard}&start_date=${startDate}&end_date=${endDate}`);
            const data = await response.json();
            this.advisors = data.advisors;
            this.tableRows = data.metrics;
        },


        formatPercentage(value) {
            return value !== undefined
                ? `${Math.round(value * 100)}%`
                : '0%'
        },
        calculateOverall(item) {
            if (item.chapter_name === 'Total Calls') {
                return this.advisors
                    .map(advisor => item[`advisor_${advisor.id}`] || 0)
                    .reduce((a, b) => a + b, 0);
            }

            const advisorValues = this.advisors
                .map(advisor => item[`advisor_${advisor.id}`])
                .filter(value => value !== undefined);

            return advisorValues.length > 0
                ? advisorValues.reduce((a, b) => a + b, 0) / advisorValues.length
                : 0;
        },
        formatValue(chapterName, value) {
            if (chapterName === 'Total Calls') {
                return value || 0
            }
            return this.formatPercentage(value)
        },
        localizeChapterName(chapterName) {
            const nameMap = {
                'Total Calls': this.$t('teamLead.total_calls'),
                'Talk Ratio': this.$t('teamLead.talk_ratio')
            }
            return nameMap[chapterName] || chapterName
        },
        formatDateDE(timestamp) {
            return new Date(timestamp).toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        },
        navigateToCallsView(advisor, chapterName, value) {
            if (value === null || value === undefined) return;

            // Find the scorecard name from the selected ID
            const selectedScorecardName = this.scorecards.find(s => s.id === this.selectedScorecard)?.name;

            // Construct full advisor name
            const advisorFullName = advisor.first_name && advisor.last_name
                ? `${advisor.first_name} ${advisor.last_name}`
                : advisor.name;

            const query = {
                advisor: advisorFullName,
                scorecard: selectedScorecardName
            };

            // Add date range with >= and <= operators
            const startDate = new Date(this.dateRange[0]);
            const endDate = new Date(this.dateRange[1]);
            query['start_date>='] = startDate.toISOString().split('T')[0];
            query['start_date<='] = endDate.toISOString().split('T')[0];

            this.router.push({
                path: `/${this.$route.params.tenant}/calls`,
                query
            });
        },
        getPercentageStyle(value, chapterName) {
            if (chapterName === 'Total Calls' || value === undefined || value === null) {
                return {};
            }

            const percentage = value * 100;
            const opacity = percentage / 100;
            const color = `rgba(89, 50, 234, ${opacity})`;

            return {
                backgroundColor: color,
                color: percentage > 70 ? 'white' : 'black'
            };
        }
    }
}
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 5px;
    overflow: hidden;
}

th,
td {
    padding: 8px;
    text-align: left;
    background-color: white;
}

th:not(:last-child),
td:not(:last-child) {
    border-right: none;
}

tr:not(:last-child) td {
    border-bottom: none;
}

th {
    background-color: white;
    font-weight: 600;
}

.vue-slider-component {
    margin: 20px 0;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    background-color: #f5f5f5;
}
</style>