<template>
  <div>
    <v-snackbar v-model="showErrorSnackbar" color="error">
      {{ errorMessage }}
    </v-snackbar>
    <v-card class="view-container card rounded-xl" elevation="0">
      <v-breadcrumbs style="margin-left: 15px;">
        <v-breadcrumbs-item :to="`/${tenant}/calls`" color="primary">{{ $t('callsDetails.calls') }}</v-breadcrumbs-item>
        <v-breadcrumbs-item>{{ $t('callsDetails.calls') }} {{ id }}</v-breadcrumbs-item>
      </v-breadcrumbs>
      <div v-if="data && data.length > 0" class="call-details">
        <p class="call-details-line">
          <v-icon color="#9197B3" size="1em">mdi-calendar</v-icon> {{ formatDate(data[0].created_at) }}
          <span style="color=#9197b3" v-if="getCustomer(data[0].customer_id)">
            <router-link :to="`/${tenant}/customers/${data[0].customer_id}`" style="color: #5932EA">
              {{ getCustomer(data[0].customer_id).first_name }} {{ getCustomer(data[0].customer_id).last_name }}
            </router-link>
          </span>
          <span style="color=#9197b3"
            v-if="advisors && advisors.length && advisors[0].first_name && advisors[0].last_name">
            {{ advisors[0].first_name }} {{ advisors[0].last_name }}
          </span>
        </p>
      </div>
      <div class="related-call-container">
        <v-btn v-for="call in filteredCalls" :key="call.id" @click="goTo(call.id)" class="related-calls">
          {{ formatButtonCaption(call.scorecard_name, call.created_at) }}
        </v-btn>
      </div>
      <!-- test end -->
      <!-- Button group to run analytics and coaching -->
      <v-btn-toggle
        v-if="loaded && (!isSpeakerNameAvailable || selectedOption || !latestTalkRatioData || !latestTalkRatioData.length || !coachingInsights || coachingInsights.length === 0)"
        class="button-group" v-model="text" elevation="0" rounded="xs" color="#9197B3" group>
        <v-btn @click="runCoaching" v-if="!coachingInsights || coachingInsights.length === 0" class="secondary-btn"
          density="compact">Coaching starten</v-btn>
      </v-btn-toggle>
      <br>
      <!-- Tabbed section with transcript and coaching insights -->
      <v-card elevation="0" class="tabbed-section">
        <v-expansion-panels style="margin-left: 30px; margin-bottom: 15px; width: 95%;">
          <v-expansion-panel v-if="parsedKeyMoments && parsedKeyMoments.length > 0">
            <v-expansion-panel-title class="h3-style" @click.stop>{{ $t('callsDetails.highlights')
              }}</v-expansion-panel-title>
            <v-expansion-panel-text style="max-height: 400px; overflow-y: auto; text-align: left; margin-left: 30px;">
              <ul class="highlights-list">
                <li v-for="moment in parsedKeyMoments" :key="moment.id" class="highlight-item">
                  <span class="highlight-emoji">{{ getEmoji(moment.key) }}</span>
                  <span v-if="moment.time" @click="playAudio(moment.time)" class="highlight-time"
                    style="cursor: pointer;">
                    [{{ formatTimestamp(moment.time) }}]
                  </span>
                  {{ moment.key }}
                </li>
              </ul>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="tabgroup">
          <v-tabs v-model="tab" color="#F2F2F7" grow slider-color="primary" selected-class="v-tab--selected">
            <v-tab key="transcript-tab" value="transcript-tab" width="50%"
              :class="{ 'tab--active': tab === 'transcript-tab', 'tab--inactive': tab !== 'transcript-tab' }">{{
                $t('callsDetails.transcript') }}</v-tab>
            <v-tab key="coaching-tab" value="coaching-tab" width="50%"
              :class="{ 'tab--active': tab === 'coaching-tab', 'tab--inactive': tab !== 'coaching-tab' }">{{
                $t('callsDetails.coaching') }}</v-tab>
          </v-tabs>
        </div>
        <v-window elevation=0 style="border:none; box-shadow: none;" v-model="tab" rounded>
          <!-- transcript tab -->
          <v-window-item value="transcript-tab">
            <v-row no-gutters>
              <v-progress-linear v-if="loadSpeakers" indeterminate color="primary"></v-progress-linear>
              <div class="tabgroup">
                <v-tabs v-model="selectedChapter" color="#F2F2F7" grow slider-color="primary"
                  selected-class="v-tab--selected">
                  <v-tab v-for="chapter in chapters" :key="chapter" :value="chapter" :class="{
                    'tab--active': selectedChapter === chapter,
                    'tab--inactive': selectedChapter !== chapter
                  }">
                    {{ chapter }}
                  </v-tab>
                </v-tabs>
              </div>
              <v-window elevation="0" style="border:none; box-shadow: none;" v-model="selectedChapter" rounded>
                <v-window-item v-for="chapter in chapters" :key="chapter" :value="chapter">
                  <v-row no-gutters>
                    <v-card v-if="!loadSpeakers" width="100%" class="px-10">
                      <div v-if="transcript && transcript.transcription && transcript.transcription.length > 0">
                        <table style="border-collapse: separate; border-spacing: 10px;">
                          <tbody>
                            <tr v-for="(segment, index) in filteredProcessedTranscription" :key="index"
                              style="font-size:15px, text-align:left, padding:10px">
                              <td @click="index === 0 ? setHoveringOverSpeaker(segment.id, index) : null"
                                :style="index === 0 ? 'text-decoration: underline; text-decoration-style: dashed; font-size:15px' : ''">
                                <div v-if="index === 0 && isHoveringOverSpeaker(segment.id, index)"
                                  class="speaker-dropdown">
                                  <select :ref="el => setSpeakerSelectRef(el, segment.id)" class="flex-select"
                                    id="initialSpeaker" v-model="selectedOption" :items="allSpeakers"
                                    @change="editingSpeaker = true"
                                    @click="keepHoverState, $event => $event.target.focus()"
                                    @click.stop="keepHoverState">
                                    <option selected value="">{{ speakerName(segment) }}</option>
                                    <option v-for="speaker in allSpeakers" :key="speaker.id" :value="speaker.id">
                                      {{ speaker.name }}
                                    </option>
                                  </select>
                                  <div>
                                    <v-btn v-if="editingSpeaker" icon @click="confirmSelection"
                                      class="confirm-reject-btn">
                                      <v-icon>mdi-check</v-icon>
                                    </v-btn>
                                    <v-btn v-if="editingSpeaker" icon @click="clearSelectionAndHideButtons(segment.id)"
                                      class="confirm-reject-btn">
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                  </div>
                                </div>
                                <div v-else>
                                  {{ speakerName(segment) }}
                                </div>
                              </td>
                              <td>{{ formatTimestamp(segment.segment_start) }}</td>
                              <td style="text-align:left">
                                <span v-for="word in segment.words" :key="word.id"
                                  :class="{ 'transcript-word': true, 'moderate-confidence': word.isModerateConfidence }"
                                  @click="playAudio(word.start !== undefined ? word.start : word.time_begin)">
                                  {{ word.display }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else-if="loaded">
                        <p>{{ $t('callsDetails.noTranscriptionAvailable') }}</p>
                      </div>
                    </v-card>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-row>
          </v-window-item>
          <!-- 2nd tab with analytics and coaching insights -->
          <v-window-item value="coaching-tab">
            <div class="shared-window-space">
              <div v-if="isSpeakerAssigned" class="charts-gallery" style="display: flex; flex-wrap: nowrap;">
                <div v-if="chartData" class="chartcard">
                  <h3>{{ $t('callsDetails.talkRatio') }} {{ advisors[0].first_name }}: {{ advisorTalkRatio }}%</h3>
                  <div class="chart-wrapper">
                    <Doughnut :data="chartData" :options="chartOptions" />
                  </div>
                </div>
                <div v-if="latestTalkSpeedData.talkspeed !== 'n/a'" class="chartcard">
                  <h3>{{ $t('callsDetails.talkSpeed') }}</h3>
                  <div style="height:180px; width: 150px">
                    <div class="chart-wrapper">
                      <span
                        :class="{ 'tag': true, 'success': latestTalkSpeedData.label === 'slow', 'grey': latestTalkSpeedData.talkspeed === 'n/a' }">{{
                          latestTalkSpeedData.label }}</span>
                      <p v-if="latestTalkSpeedData.talkspeed !== 'n/a'" style="margin-top: 10px"><strong>{{
                        latestTalkSpeedData.talkspeed }}</strong></p>
                      <p v-if="latestTalkSpeedData.talkspeed !== 'n/a'" style="margin-top: 10px">{{
                        latestTalkSpeedData.unit }}</p>
                      <p v-else class="grey-tag">n/a</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="margin:25px">
                <FeedbackCard v-if="advisors && advisors.length && advisors[0].first_name && advisors[0].last_name"
                  :advisorName="`${advisors[0].first_name} ${advisors[0].last_name}`" :callId="id"
                  :advisor_user_id="advisors[0].user_id" @playAudio="playAudio"
                  style="flex: 1; margin-right: 15px; width: 100%; height: auto; z-index: 1;" />
              </div>
              <div class="coachingplaceholder" v-if="creatingCoaching">
                <div class="spring-animation">
                  <svg width="141" height="141" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.589 111.156L64.896 63.96L111.303 11.667L129.024 28.935L76.359 75.003L29.082 128.877L11.589 111.156ZM111.078 128.313L64.332 75.003L11.361 29.16L29.307 11.667L76.02 63.732L128.799 111.156L111.078 128.313ZM57.996 140.544L62.139 69.672L57.996 0H82.695L77.985 69.672L82.695 140.544H57.996ZM0 57.768L70.002 61.542L140.769 57.849V82.548L70.158 77.535L0 82.548V57.768Z"
                      fill="#5932EA" />
                  </svg>
                </div>
                <div class="placeholdertext">
                  <h3>{{ $t('callsDetails.coachingInProgress') }}</h3>
                  <p>{{ $t('callsDetails.coachingInProgressDescription') }} {{
                    currentUser.email }}, {{ $t('callsDetails.coachingAvailable') }}.</p>
                </div>
              </div>
            </div>
            <h3 class="scorecard-title">
              <span>{{ $t('callsDetails.scorecard') }}</span>
              <span v-if="scorecardScore !== null" class="scorecard-score" :class="getScoreColorClass(scorecardScore)">
                {{ formatNumber(scorecardScore * 100) }}%
              </span>
            </h3>
            <div class="contents" style="display: flex;">
              <div class="chapters" v-if="chapterNames && chapterNames.length > 0">
                <div v-for="chapterName in chapterNames" :key="chapterName" class="chapter"
                  :class="{ 'selected-chapter': selectedChapter === chapterName }" @click="selectChapter(chapterName)">
                  <span class="chapter-name">{{ chapterName }}</span>
                  <span v-if="chapterScores[chapterName] !== undefined" class="chapter-score" :class="[
                    getScoreColorClass(chapterScores[chapterName]),
                    { 'selected-score': selectedChapter === chapterName }
                  ]">{{ formatNumber(chapterScores[chapterName] * 100) }}%</span>
                </div>
              </div>
              <div class="topics" style="width: 100%; text-align: left;">
                <div v-if="selectedChapter" :key="selectedChapter">
                  <ul style="list-style: none; padding-left: 0;">
                    <li v-for="topic in selectedChapterTopics" :key="topic.topic_id" class="topic-item">
                      <div class="full-width">
                        <div style="display: flex; justify-content: space-between;">
                          <div style="flex: 1;">
                            <h3>
                              <span v-if="topic.topic_score === 1">✅</span>
                              <span v-if="topic.topic_score === 0">❌</span>
                              {{ topic.topic_name }}
                              <v-btn v-if="topic.topic_reasoning" icon variant="outlined"
                                style="background-color: white; border-radius: 8px; border-color: #9197B3; color: #9197B3; width: 24px; height: 24px;">
                                <v-tooltip activator="parent" location="bottom">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">?</span>
                                  </template>
                                  <span>{{ topic.topic_reasoning }}</span>
                                </v-tooltip>
                              </v-btn>
                              <!-- <v-btn icon="mdi-play-outline" variant="outlined" @click="showOverlay"
                                style="background-color: white; border-radius: 8px; border-color: #9197B3; color: #9197B3; width: 24px; height: 24px;">
                              </v-btn> -->
                              <v-btn icon="mdi-play" v-if="topic.seminar_link" variant="tonal"
                                @click="showOverlay(getSeminarLink(topic))"
                                style="background-color: white; border-radius: 8px; border-color: #9197B3; color: #9197B3; width: 24px; height: 24px;">
                              </v-btn>
                            </h3>
                          </div>
                        </div>
                        <div v-if="topic.topic_score === 1"
                          style="display: flex; justify-content: space-between; margin-top: 10px;">
                          <div style="flex: 0.15;">
                            <p v-if="topic.advisor_statement" style="margin-bottom: 25px; color:#9197B3">
                              {{ $t('callsDetails.situation') }}</p>
                            <p v-if="topic.follow_up && topic.follow_up.length > 0 && topic.follow_up_scores.length > 0"
                              style="margin-bottom: 25px;color:#9197B3">
                              {{ $t('callsDetails.followUp') }}</p>
                            <p v-if="topic.cross_selling_topic" style="margin-bottom: 25px;color:#9197B3">
                              {{ $t('callsDetails.crossSelling') }}</p>
                            <p v-if="topic.love_words && (topic.love_words.length > 0 || topic.hate_words.length > 0)"
                              style="margin-bottom: 25px; color:#9197B3">
                              {{ $t('callsDetails.brand') }}</p>
                          </div>
                          <div style="flex: 0.85;">
                            <p v-if="topic.advisor_statement"
                              style="border-left: 2px solid #9197B3; padding-left: 10px; font-style: italic;">
                              {{
                                topic.advisor_statement }}</p>
                            <div v-if="topic.follow_up">
                              <div style="display: flex; flex-wrap: wrap; padding: 3px;">
                                <span v-for="(followUp, index) in topic.follow_up" :key="index" class="tag"
                                  :class="topic.follow_up_scores[index] === 1 ? 'success' : 'failure'">
                                  {{ followUp }}
                                </span>
                              </div>
                            </div>
                            <div v-if="topic.cross_selling_topic" style="display: flex; flex-wrap: wrap; padding: 3px;">
                              <p class="tag success">{{ topic.cross_selling_topic }}</p>
                            </div>
                            <div v-if="topic.love_words > 0 || topic.hate_words > 0"
                              style="display: flex; flex-wrap: wrap; padding: 3px;">
                              <span v-for="(word, index) in topic.love_words" :key="'love-' + index"
                                class="tag success">
                                {{ word }}
                              </span>
                              <span v-for="(word, index) in topic.hate_words" :key="'hate-' + index"
                                class="tag failure">
                                {{ word }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- Icons with tooltips -->
                        <div style="display: flex; gap: 5px;">
                          <v-tooltip location="bottom" text="Hilfreich">
                            <template v-slot:activator="{ props }">
                              <div style="display: inline-block;"
                                @click="topic.ratedHelpful = !topic.ratedHelpful, rateTopic(topic, 'helpful')"
                                v-bind="props">
                                <ThumbsUp :color="topic.ratedHelpful ? '#5932EA' : '#9197B3'" />
                              </div>
                            </template>
                          </v-tooltip>
                          <v-tooltip location="bottom" text="{{ $t('callsDetails.notHelpful') }}">
                            <template v-slot:activator="{ props }">
                              <div style="display: inline-block;" @click="showRatingModal = true; currentTopic = topic"
                                v-bind="props">
                                <ThumbsDown :color="topic.ratedNotHelpful ? '#5932EA' : '#9197B3'" />
                              </div>
                            </template>
                          </v-tooltip>
                        </div>
                        <v-dialog v-model="showRatingModal" persistent max-width="600px">
                          <v-card>
                            <v-card-title>
                              {{ $t('callsDetails.feedback') }}
                            </v-card-title>
                            <v-card-text>
                              <v-textarea v-model="ratingComment" label="Kommentar" rows="3"></v-textarea>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="primary" text @click="showRatingModal = false">{{ $t('callsDetails.back')
                                }}</v-btn>
                              <v-btn color="primary" @click="rateTopic(currentTopic, 'not helpful')">{{
                                $t('callsDetails.send') }}</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </v-window-item>
        </v-window>
        <div class="ner-accordion-container">
          <NERAccordion v-if="nerResults" :nerResults="nerResults" />
        </div>
      </v-card>
    </v-card>
  </div>
  <div>
    <p style="margin: 10px; font-size: 8pt; color: #9197B3;"> {{ $t('callsDetails.todayCanMakeMistakes') }}
    </p>
    <div v-if="audioFile" class="audio-container">
      <audio ref="audioPlayer" controls class="full-width-audio">
        <source :src="audioFile" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
    </div>
  </div>

  <!-- v-overlay for youtube seminar videos -->
  <v-overlay v-model="overlayVisible" :absolute="absolute" opacity="0.9">
    <div class="d-flex align-center justify-center" style="height: 100vh; width: 100vw;">
      <v-card>
        <v-card-title>
          <v-btn icon @click="hideOverlay">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <iframe :src="overlaySrc" width="560" height="315" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </v-card-text>
      </v-card>
    </div>
  </v-overlay>
</template>


<script>
import axios from 'axios';
import { ref, onMounted, watch, nextTick, reactive, computed, onBeforeUnmount } from "vue";
import { supabase, userState } from "../supabase";
import { useRoute, useRouter } from "vue-router";
import { Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);
import { ThumbsUp, ThumbsDown } from 'lucide-vue-next';
import NERAccordion from '@/components/NERAccordion.vue';
import FeedbackCard from '@/components/FeedbackCard.vue';
import i18n from '@/i18n';

export default {
  components: {
    Doughnut, ThumbsUp, ThumbsDown, NERAccordion, FeedbackCard
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const creatingCoaching = ref(false);
    const id = ref(null);
    const data = ref([]);
    const transcript = ref(null);
    const audioFile = ref(null);
    const audioPlayer = ref(null);
    const selectedOption = ref(null);
    const customers = ref([]);
    const advisors = ref([]);
    const talkSpeedData = ref(null);
    const coachingInsights = ref([]);
    const documentTypes = ref([]);
    const selectedDocumentType = ref(null);
    const allSpeakers = computed(() => {
      const callAdvisor = advisors.value.find(a => a.user_id === data.value[0]?.user_id);
      const callCustomer = customers.value.find(c => c.id === data.value[0]?.customer_id);

      return [
        ...(callCustomer ? [{ id: 'customer-' + callCustomer.id, name: `${callCustomer.first_name} ${callCustomer.last_name}` }] : []),
        ...(callAdvisor ? [{ id: 'advisor-' + callAdvisor.user_id, name: `${callAdvisor.first_name} ${callAdvisor.last_name}` }] : [])
      ];
    });
    const latestTalkRatioData = ref([]);
    const latestTalkSpeedLabel = ref('');
    const sentimentRatios = ref({});
    const tab = ref(route.params.tab || 'transcript-tab');
    const documents = ref([]);
    const loaded = ref(false);
    let showErrorSnackbar = ref(false);
    let errorMessage = ref("");
    const chapterNames = ref([]);
    const selectedChapter = ref(null);
    const grouped = ref({});
    const latestTalkSpeedData = ref([]);
    const tenant = route.params.tenant;
    const advisorEmail = ref('');
    const showRatingModal = ref(false);
    const ratingComment = ref('');
    const hoveringOverSpeaker = ref(null);
    const selectedSpeaker = ref({});
    const hoverTimeout = ref(null);
    let editingSpeaker = ref(false);
    const speakerSelectRefs = reactive({});
    let isSpeakerAssigned = ref(false);
    const loadSpeakers = ref(false);
    const nerResults = ref('');
    const parsedKeyMoments = ref([]);
    const filteredCalls = ref([]);

    const overlaySrc = ref('');
    const overlayVisible = ref(false);
    const showOverlay = (seminarLink) => {
      if (seminarLink) {
        overlayVisible.value = true;
        overlaySrc.value = seminarLink;
      }
    };

    const hideOverlay = () => {
      overlayVisible.value = false;
      overlaySrc.value = '';
    };
    const getSeminarLink = (topic) => {
      return topic.seminar_link ? topic.seminar_link : '';
    };



    const getCustomer = (customerId) => {
      return customers.value.find(customer => customer.id === customerId);
    };

    const resetState = () => {
      selectedChapter.value = null;
    };

    watch(id, async (newId) => {
      if (newId) {
        nextTick(async () => {
          await reloadData();
          await fetchCallsAndScorecards();
        });
      }
    });

    onBeforeUnmount(() => {
      resetState();
    });

    const getEmoji = (key) => {
      const emojiMap = {
        'Kunde erwähnt einen anderen Berater/Wettbewerber': '🏢',
        'Kunde und Berater stimmen zu oder nicht zu Ansatz oder nächsten Schritten': '🤝',
        'Berater bietet etwas an (z.B. Zweitmeinung), aber Kunde lehnt ab': '🚫',
        'Entscheidung über Follow-up oder Kauf wird getroffen / nicht getroffen': '💼',
        'Kunde weigert sich, persönliche oder Kontaktinformationen zu teilen': '🔒',
        'Berater stellt eine Frage, beantwortet sie aber selbst, bevor der Kunde wirklich antworten kann': '🗣️',
        'Bedarf vernachlässigt': '🧐',
        'Advisor monolog (>80s)': '🕰️'
      };
      return emojiMap[key] || '📌'; // Default emoji if no match found
    };

    // const parseKeyMoments = (keyMomentsJson) => {
    //   try {
    //     const keyMoments = JSON.parse(keyMomentsJson);
    //     return keyMoments.KeyMoments.flatMap((moment, index) => {
    //       const [key, value] = Object.entries(moment)[0];
    //       if (value !== 'N/A') {
    //         return {
    //           id: index + 1,
    //           key,
    //           value,
    //           time: moment.segment_start || null // Use individual segment_start for each moment
    //         };
    //       }
    //       return null;
    //     }).filter(Boolean);
    //   } catch (error) {
    //     console.error('Error parsing key moments:', error);
    //     showErrorSnackbar.value = true;
    //     errorMessage.value = 'Error parsing key moments';
    //     return [];
    //   }
    // };

    const parseKeyMoments = (keyMomentsJson) => {
      try {
        const keyMoments = JSON.parse(keyMomentsJson);
        const parsedMoments = keyMoments.KeyMoments.flatMap((moment, index) => {
          const [key, value] = Object.entries(moment)[0];
          if (value !== 'N/A') {
            return {
              id: index + 1,
              key,
              value,
              time: moment.segment_start || null // Use individual segment_start for each moment
            };
          }
          return null;
        }).filter(Boolean);
        // Sort the parsed moments by time in ascending order
        return parsedMoments.sort((a, b) => a.time - b.time);
      } catch (error) {
        console.error('Error parsing key moments:', error);
        showErrorSnackbar.value = true;
        errorMessage.value = 'Error parsing key moments';
        return [];
      }
    };

    const jumpToAudioTime = (time) => {
      if (audioPlayer.value) {
        audioPlayer.value.currentTime = time;
        audioPlayer.value.play();
      }
    };

    const decodeHtml = (html) => {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    };

    const firstSegmentSpeakerId = computed(() => {
      return transcript.value?.transcription[0]?.speaker_id || null;
    });

    watch(firstSegmentSpeakerId, async (newSpeakerId) => {
      if (newSpeakerId !== null) {
        selectedSpeaker.value[firstSegmentSpeakerId.value] = newSpeakerId;
        await nextTick();
      }
    }, { immediate: true });

    const keepHoverState = () => {
    };

    const setHoveringOverSpeaker = (segmentId) => {
      if (hoveringOverSpeaker.value === segmentId) {
        speakerSelectRefs[segmentId].focus();
        hoveringOverSpeaker.value = null;
      } else {
        hoveringOverSpeaker.value = segmentId;
      }
    };

    const focusSelect = (segmentId) => {
      if (speakerSelectRefs[segmentId]) {
        speakerSelectRefs[segmentId].focus();
      }
    };

    const setSpeakerSelectRef = (element, segmentId) => {
      if (element) {
        speakerSelectRefs[segmentId] = element;
      }
    };

    const clearHoveringOverSpeaker = (segmentId) => {
      hoverTimeout.value = setTimeout(() => {
        if (hoveringOverSpeaker.value === segmentId) {
          hoveringOverSpeaker.value = null;
        }
      }, 100);
    };

    const isHoveringOverSpeaker = (segmentId) => {
      return hoveringOverSpeaker.value === segmentId;
    };

    const clearSelectionAndHideButtons = () => {
      editingSpeaker.value = false;
      hoveringOverSpeaker.value = null;
      if (hoverTimeout.value) {
        clearTimeout(hoverTimeout.value);
        hoverTimeout.value = null;
      }
      window.location.reload();
    };

    const parseAndNormalizeTopic = (topic) => {
      const normalizedTopic = { ...topic };

      // Normalize keys with hyphens (excluding follow-up related keys)
      if (normalizedTopic["hate words"]) {
        normalizedTopic.hate_words = normalizedTopic["hate words"];
        delete normalizedTopic["hate words"];
      }
      if (normalizedTopic["love words"]) {
        normalizedTopic.love_words = normalizedTopic["love words"];
        delete normalizedTopic["love words"];
      }

      // Handle nested objects in 'topic_id'
      if (typeof normalizedTopic.topic_id === 'object') {
        Object.assign(normalizedTopic, normalizedTopic.topic_id);
        delete normalizedTopic.topic_id;
      }

      // Convert score strings to numbers
      normalizedTopic.topic_score = Number(normalizedTopic.topic_score);
      normalizedTopic.cross_selling_score = Number(normalizedTopic.cross_selling_score);

      return normalizedTopic;
    };

    const processInsightsData = async () => {
      try {
        const { data: callDetails, error } = await supabase
          .from('calls')
          .select('insights')
          .eq('id', id.value)
          .single();
        if (error) {
          console.error('Error fetching call details:', error);
          showErrorSnackbar.value = true;
          errorMessage.value = error.message;
          return;
        }

        if (callDetails && callDetails.insights) {
          if (callDetails.insights.talk_ratio && callDetails.insights.talk_ratio.length) {
            latestTalkRatioData.value = callDetails.insights.talk_ratio[callDetails.insights.talk_ratio.length - 1].data;
          }
          if (callDetails.insights.talkspeed && callDetails.insights.talkspeed.length) {
            latestTalkSpeedData.value = callDetails.insights.talkspeed[callDetails.insights.talkspeed.length - 1];
          }
          if (callDetails.insights.sentiment_analysis) {
            sentimentRatios.value = callDetails.insights.sentiment_analysis.sentiment_ratios;
          }
          if (callDetails.insights.coaching_insights) {
            let insightsContent = null;

            for (let i = callDetails.insights.coaching_insights.length - 1; i >= 0; i--) {
              const potentialInsights = callDetails.insights.coaching_insights[i];
              if (potentialInsights && potentialInsights.coaching_insights && Array.isArray(potentialInsights.coaching_insights.content)) {
                insightsContent = potentialInsights.coaching_insights.content.map(parseAndNormalizeTopic);
                break;
              }
            }
            if (insightsContent) {
              coachingInsights.value = groupByChapter(insightsContent);
            } else {
              console.error("No valid coaching insights content found.");
              showErrorSnackbar.value = true;
              errorMessage.value = "Keine Coaching-Insights gefunden.";
            }
          }
        }
      } catch (error) {
        console.error('Error processing insights data:', error);
        showErrorSnackbar.value = true;
        errorMessage.value = error.message;
      }
    };

    const advisorTalkRatio = computed(() => {
      const advisorRatio = latestTalkRatioData.value.find(ratio =>
        getSpeakerRole(ratio.role_id) === 'advisor'
      );
      return advisorRatio ? Math.round(advisorRatio.talk_ratio * 100) : 0;
    });

    const chartData = computed(() => {
      if (latestTalkRatioData.value && latestTalkRatioData.value.length > 0) {
        const labels = latestTalkRatioData.value.map(ratio =>
          speakerName({ role_id: ratio.role_id }, getSpeakerRole(ratio.role_id))
        );
        const data = latestTalkRatioData.value.map(ratio => Math.round(ratio.talk_ratio * 100));

        return {
          labels: labels,
          datasets: [{
            label: 'Talk Ratio',
            data: data,
            backgroundColor: ['#9197B3', '#F2F2F7'],
            hoverOffset: 4
          }]
        };
      } else {
        return { labels: [], datasets: [{ label: 'Talk Ratio', data: [], backgroundColor: [], hoverOffset: 4 }] };
      }
    });

    const sentimentChartData = computed(() => {
      if (sentimentRatios.value && Object.keys(sentimentRatios.value).length > 0) {
        const orderedLabels = ['positiv', 'neutral', 'negativ'];
        const data = orderedLabels.map(label => {
          const ratio = sentimentRatios.value[label];
          return ratio !== undefined ? Math.round(ratio * 100) : 0;
        });
        return {
          labels: orderedLabels,
          datasets: [
            {
              label: 'Sentiment Analysis',
              data: data,
              backgroundColor: orderedLabels.map(label => {
                switch (label) {
                  case 'positiv': return '#65C8CD';
                  case 'neutral': return '#9197B3';
                  case 'negativ': return '#EC5B84';
                  default: return '#9197B3';
                }
              }),
              hoverOffset: 4
            }
          ]
        };
      } else {
        return {
          labels: ['positiv', 'neutral', 'negativ'],
          datasets: [
            {
              label: 'Sentiment Analysis',
              data: [0, 0, 0],
              backgroundColor: ['#65C8CD', '#9197B3', '#EC5B84'],
              hoverOffset: 4
            }
          ]
        };
      }
    });

    const chartOptions = ref({
      responsive: true,
      maintainAspectRatio: true,
      cutout: '80%',
      plugins: {
        legend: {
          position: 'bottom',
          Height: 30,
          labels: {
            boxWidth: 5,
            font: {
              family: 'Inter, sans-serif',
            },
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'percent' }).format(context.parsed / 100);
              }
              return label;
            }
          }
        }
      }
    });

    const groupByChapter = (topicsArray) => {
      const grouped = {};
      topicsArray.forEach((topic) => {
        const chapterName = topic.chapter_name;
        if (!grouped[chapterName]) {
          grouped[chapterName] = [];
        }
        grouped[chapterName].push(topic);
      });
      return grouped;
    };

    const runAnalytics = async () => {
      try {
        const response = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/callanalytics', { call_id: id.value });
        console.log('API Response:', response);
        await processInsightsData();
      } catch (error) {
        console.error(error);
        showErrorSnackbar.value = true;
        errorMessage.value = error.message;
      }
    };

    const rateTopicAsHelpful = async (topic) => {
      try {
        const currentUser = userState.currentUser;
        if (!currentUser) {
          throw new Error('User not found');
        }
        const { error } = await supabase.from('ratings').insert([
          {
            created_at: new Date(),
            user_id: currentUser.id,
            call_id: id.value,
            topic_id: topic.topic_id,
            rating_value: 'helpful'
          }
        ]);
        if (error) {
          throw error;
        }
      } catch (error) {
        console.error('Error adding rating:', error);
        showErrorSnackbar.value = true;
        errorMessage.value = error.message;
      }
    };

    const rateTopic = async (topic, ratingValue) => {
      try {
        const currentUser = userState.currentUser;
        if (!currentUser) {
          throw new Error('User not found');
        }
        const ratingPayload = {
          created_at: new Date(),
          user_id: currentUser.id,
          call_id: id.value,
          topic_id: topic.topic_id,
          rating_value: ratingValue,
          rating_comment: ratingValue === 'not helpful' ? ratingComment.value : null
        };
        const { data, error } = await supabase.from('ratings').insert([ratingPayload]);
        if (error) {
          throw error;
        }
        if (ratingValue === 'helpful') {
          topic.ratedHelpful = true;
        } else {
          topic.ratedNotHelpful = true;
        }
        console.log('Rating added successfully:', data);
        ratingComment.value = '';
        showRatingModal.value = false;
      } catch (error) {
        console.error('Error adding rating:', error);
        showErrorSnackbar.value = true;
        errorMessage.value = error.message;
      }
    };

    function formatDate(isoString) {
      const date = new Date(isoString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    const confirmSelection = async () => {
      const firstDashIndex = selectedOption.value.indexOf('-');
      const firstSpeaker = selectedOption.value.substring(0, firstDashIndex);
      let roleId = selectedOption.value.substring(firstDashIndex + 1);
      loadSpeakers.value = true;

      let secondSpeaker, secondRoleId;

      if (firstSpeaker === 'advisor') {
        secondSpeaker = 'customer';
        secondRoleId = data.value[0]?.customer_id;
      } else if (firstSpeaker === 'customer') {
        secondSpeaker = 'advisor';
        secondRoleId = data.value[0]?.user_id;
      }

      const postData = {
        firstSpeaker: firstSpeaker,
        id: id.value,
        roleId: roleId,
        secondSpeaker: secondSpeaker,
        secondRoleId: secondRoleId
      };

      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/assign_speaker', postData)
        .then(() => {
          hoveringOverSpeaker.value = null;
          loadSpeakers.value = false;
          window.location.reload();
          reloadData();
        })
        .catch(error => {
          showErrorSnackbar.value = true;
          errorMessage.value = error.message;
        });
    };

    const runCoaching = async () => {
      creatingCoaching.value = true;
      const currentUser = userState.currentUser;
      if (!currentUser) {
        console.error('User not found');
        showErrorSnackbar.value = true;
        errorMessage.value = 'User not found';
        return;
      }

      const payload = {
        call_id: id.value,
        transcript: transcript.value,
        user_email: currentUser.email,
      };
      console.log('payload', payload);
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/coaching_insights', payload)
        .then(() => {
          creatingCoaching.value = false;
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
          showErrorSnackbar.value = true;
          errorMessage.value = error.message;
        });
    };

    const reloadData = async () => {
      const user = userState.currentUser;
      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [user.id]);
      if (orgError) throw orgError;
      const organisationIds = organisations.map(org => org.id);
      const { data: calls, error } = await supabase.from("calls").select("*").eq('id', id.value).in('organisation', organisationIds);
      if (error) {
        console.error('Error fetching calls:', error);
        showErrorSnackbar.value = true;
        errorMessage.value = error.message;
        return;
      }
      data.value = calls;
    };

    onMounted(async () => {
      try {
        audioPlayer.value = document.querySelector('audio');
        selectedChapter.value = i18n.global.t('callsDetails.CompleteTranscript');
        id.value = route.params.id;
        await reloadData();
        const user = userState.currentUser;
        if (!user) throw new Error('User not found');
        const { data: organisations, error: orgError } = await supabase
          .from('organisations')
          .select('id')
          .contains('associated_user_ids', [user.id]);
        if (orgError) {
          console.error(orgError);
          showErrorSnackbar.value = true;
          errorMessage.value = orgError.message;
          return;
        }
        const organisationIds = organisations.map(org => org.id);

        if (window.location.hash === '#coaching') {
          tab.value = 'coaching-tab';
        }
        processInsightsData();

        chapterNames.value = Object.keys(grouped);
        Object.assign(grouped, grouped);

        const { data: callData } = await supabase
          .from('calls')
          .select('key_moments')
          .eq('id', id.value)
          .single();

        if (callData && callData.key_moments) {
          parsedKeyMoments.value = parseKeyMoments(callData.key_moments);
        }

        const { data: calls, error } = await supabase
          .from("calls")
          .select("*, ner")
          .eq('id', id.value)
          .in('organisation', organisationIds);
        if (error) {
          console.error(error);
          showErrorSnackbar.value = true;
          errorMessage.value = error.message;
          return;
        }
        data.value = calls;

        if (calls && calls.length > 0 && calls[0].ner) {
          nerResults.value = calls[0].ner;
        }

        if (calls.length > 0 && calls[0].user_id) {
          const { data: fetchedAdvisors, error: advisorError } = await supabase
            .from("advisors")
            .select("user_id, first_name, last_name, organisation")
            .eq("user_id", calls[0].user_id)
            .in('organisation', organisationIds);
          if (advisorError) throw advisorError;
          advisors.value = fetchedAdvisors
            .filter(advisor => advisor.first_name && advisor.last_name);
        }

        const { data: fetchedCustomers, error: customerError } = await supabase
          .from("customers")
          .select("id, first_name, last_name")
          .in('organisation', organisationIds);
        if (customerError) {
          console.error(customerError);
          console.log("Somethign wong with customer")
          showErrorSnackbar.value = true;
          errorMessage.value = customerError.message;
          return;
        }
        customers.value = fetchedCustomers
          .filter(customer => customer.first_name && customer.last_name);

        if (calls.length > 0) {
          const transcriptFileUrl = calls[0].transcript_file_name;
          if (transcriptFileUrl) {
            const transcriptFileName = new URL(transcriptFileUrl).pathname.split("/").pop();
            const { data: transcriptFile, error: transcriptError } = await supabase
              .storage.from('coveredbucket').download(transcriptFileName);
            if (transcriptError) {
              console.error(transcriptError);
              showErrorSnackbar.value = true;
              errorMessage.value = transcriptError.message;
              return;
            }
            if (route.query.tab === 'coaching') {
              nextTick(() => {
                tab.value = 'coaching-tab';
              });
            }
            const fileContents = await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsText(transcriptFile);
            });
            transcript.value = JSON.parse(fileContents);
          }
        }

        const audioFileUrl = calls[0].file_reference;
        if (audioFileUrl) {
          try {
            const { data: audioBlob, error: audioError } = await supabase.storage.from('coveredbucket').download(audioFileUrl);
            if (audioError) {
              console.error("Error fetching audio from Supabase:", audioError);
            } else {
              const audioUrl = URL.createObjectURL(audioBlob);
              audioFile.value = audioUrl;
              loaded.value = true;
            }
          } catch (audioError) {
            console.error("Error fetching audio from Supabase:", audioError);
            showErrorSnackbar.value = true;
            errorMessage.value = audioError.message;
          }
        }
      } catch (error) {
        console.warn(error.message);
        showErrorSnackbar.value = true;
        errorMessage.value = error.message;
      }

      const { data: callDetails } = await supabase
        .from('calls')
        .select('insights')
        .eq('id', id.value)
        .single();
      if (callDetails && callDetails.insights) {
        processInsightsData(callDetails.insights);
      }

      if (callDetails && callDetails.insights && callDetails.insights.coaching_insights) {
        const coachingContent = callDetails.insights.coaching_insights.content;
        try {
          coachingInsights.value = coachingContent;
        } catch (error) {
          console.error('Error parsing coaching insights content:', error);
          showErrorSnackbar.value = true;
          errorMessage.value = error.message;
        }
      }

      if (callDetails && callDetails.insights && callDetails.insights.sentiment_analysis) {
        sentimentRatios.value = callDetails.insights.sentiment_analysis.sentiment_ratios;
      }
    });

    const getScoreColorClass = (score) => {
      const percentage = score * 100;
      if (percentage < 33) return 'score-red';
      if (percentage < 67) return 'score-orange';
      return 'score-green';
    };

    const formatNumber = (value) => {
      return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: false
      }).format(value);
    };
    const formatTimestamp = (timestamp) => {
      const seconds = Math.floor(timestamp);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);

      if (hours > 0) {
        return `${hours.toString().padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;
      } else {
        return `${minutes.toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;
      }
    };

    const playAudio = (timestamp) => {
      if (audioPlayer.value && Number.isFinite(timestamp)) {
        audioPlayer.value.currentTime = timestamp;
        audioPlayer.value.play();
      } else {
        console.error('Invalid timestamp for audio playback:', timestamp);
      }
    };

    const fetchCallsAndScorecards = async () => {
      try {
        const { data: callsData, error: callsError } = await supabase
          .from("calls")
          .select("id, scorecard_id, insights, created_at")
          .eq("customer_id", data.value[0].customer_id)
          .not('insights', 'is', null)
          .not('scorecard_id', 'is', null)
          .neq("id", id.value)
          .order('created_at', { ascending: true });

        if (callsError) {
          throw callsError;
        }
        const { data: scorecardsData, error: scorecardsError } = await supabase
          .from("scorecards")
          .select("id, name")

        if (scorecardsError) {
          throw scorecardsError;
        }

        callsData.forEach(call => {
          const matchingScorecard = scorecardsData.find(
            scorecard => scorecard.id === call.scorecard_id
          );
          call.scorecard_name = matchingScorecard ? matchingScorecard.name : "Unknown";
        });

        const uniqueCalls = [];
        const seenScorecardIds = new Set();
        callsData.forEach(call => {
          if (call.id !== id.value && !seenScorecardIds.has(call.scorecard_id)) {
            uniqueCalls.push(call);
            seenScorecardIds.add(call.scorecard_id);
          }
        });
        uniqueCalls.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        filteredCalls.value = uniqueCalls;
      } catch (error) {
        console.error(error);
        showErrorSnackbar.value = true;
        errorMessage.value = error.message;
      }
    };

    const formatButtonCaption = (scorecardName, createdAt) => {
      const formattedDate = formatDate(createdAt);
      const maxScorecardNameLength = 20;
      const truncatedScorecardName =
        scorecardName.length > maxScorecardNameLength
          ? scorecardName.slice(0, maxScorecardNameLength - 3) + "..."
          : scorecardName;
      return `${truncatedScorecardName} ${formattedDate}`;
    };
    const goTo = (callId) => {
      router.push({ path: `/${tenant}/calls/${callId}` }).then(() => {
        window.location.reload();
      });
    };

    const speakerName = (segment, role) => {
      const speakerRole = role ? role : segment.speaker_role;
      if (speakerRole) {
        isSpeakerAssigned.value = true;
        if (speakerRole === "customer") {
          const customer = customers.value.find(customer => customer.id.toString() === segment.role_id.toString());
          if (!customer) {
            console.error(`Customer not found for role_id: ${segment.role_id}`);
            showErrorSnackbar.value = true;
            errorMessage.value = `Customer not found for role_id: ${segment.role_id}`;
          }
          return customer ? customer.first_name : 'Wähle...';
        } else if (speakerRole === "advisor") {
          const advisor = advisors.value.find(advisor => advisor.user_id.toString() === segment.role_id.toString());
          if (!advisor) {
            console.error(`Advisor not found for role_id: ${segment.role_id}`);
            showErrorSnackbar.value = true;
            errorMessage.value = `Advisor not found for role_id: ${segment.role_id}`;
          }
          return advisor ? advisor.first_name : 'Wähle...';
        } else {
          console.error(`Unexpected speaker role: ${speakerRole}`);
          showErrorSnackbar.value = true;
          errorMessage.value = `Unexpected speaker role: ${speakerRole}`;
        }
      } else {
        isSpeakerAssigned.value = false;
        console.error('Speaker role is not defined');
      }
      return 'Wähle...';
    };

    const getSpeakerRole = (roleId) => {
      const roleIdString = roleId.toString();
      const customerMatch = customers.value.some(customer => customer.id.toString() === roleIdString);
      if (customerMatch) {
        return 'customer';
      }
      const advisorMatch = advisors.value.some(advisor => advisor.user_id === roleIdString);
      if (advisorMatch) {
        return 'advisor';
      }
      console.error(`Unknown speaker role for role_id: ${roleId}`);
      return null;
    };

    const selectChapter = (chapterName) => {
      selectedChapter.value = chapterName;
    };

    const callScores = ref(null);


    const fetchCallScores = async () => {
      try {
        const { data, error } = await supabase
          .from('calls')
          .select('call_scores')
          .eq('id', id.value)
          .single();

        if (error) throw error;
        // Parse the JSON string
        callScores.value = JSON.parse(data.call_scores);
      } catch (error) {
        console.error('Error fetching call scores:', error);
        showErrorSnackbar.value = true;
        errorMessage.value = 'Failed to fetch call scores';
      }
    };

    onMounted(() => {
      fetchCallScores();
    });

    const scorecardScore = computed(() => {
      return callScores.value ? callScores.value.scorecard_score : null;
    });

    const chapterScores = computed(() => {
      if (!callScores.value) return {};
      return Object.entries(callScores.value)
        .filter(([key]) => key !== 'scorecard_score')
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
    });

    return {
      getScoreColorClass,
      formatNumber,
      chapterScores,
      scorecardScore,
      id,
      data,
      transcript,
      formatTimestamp,
      audioFile,
      audioPlayer,
      playAudio,
      parsedKeyMoments,
      jumpToAudioTime,
      selectedOption,
      getEmoji,
      confirmSelection,
      customers,
      advisors,
      speakerName,
      getSpeakerRole,
      talkSpeedData,
      runCoaching,
      coachingInsights,
      showErrorSnackbar,
      errorMessage,
      advisorTalkRatio,
      documentTypes,
      selectedDocumentType,
      allSpeakers,
      formatDate,
      tab,
      documents,
      latestTalkRatioData,
      keepHoverState,
      latestTalkSpeedLabel,
      latestTalkSpeedData,
      sentimentRatios,
      runAnalytics,
      loaded,
      selectChapter,
      decodeHtml,
      tenant,
      selectedChapter,
      grouped,
      chartData,
      chartOptions,
      sentimentChartData,
      advisorEmail,
      creatingCoaching,
      rateTopicAsHelpful,
      showRatingModal,
      ratingComment,
      rateTopic,
      hoveringOverSpeaker,
      selectedSpeaker,
      setHoveringOverSpeaker,
      clearHoveringOverSpeaker,
      isHoveringOverSpeaker,
      clearSelectionAndHideButtons,
      editingSpeaker,
      focusSelect,
      setSpeakerSelectRef,
      isSpeakerAssigned,
      loadSpeakers,
      getCustomer,
      nerResults,
      filteredCalls,
      formatButtonCaption,
      goTo,
      overlayVisible,
      showOverlay,
      hideOverlay,
      overlaySrc,
      getSeminarLink
    };
  },

  computed: {
    currentUser() {
      return userState.currentUser;
    },

    chapterNames() {
      if (!this.coachingInsights) return [];
      return Object.keys(this.coachingInsights);
    },
    selectedChapterTopics() {
      if (this.coachingInsights && this.selectedChapter && this.coachingInsights[this.selectedChapter]) {
        return this.coachingInsights[this.selectedChapter];
      }
      return [];
    },


    filteredProcessedTranscription() {
      if (this.selectedChapter === i18n.global.t('callsDetails.CompleteTranscript')) {
        return this.processedTranscription;
      }
      return this.processedTranscription.filter(segment => segment.chapter === this.selectedChapter);
    },

    chapters() {
      if (!this.processedTranscription || this.processedTranscription.length === 0) {
        return [];
      }

      const chapterOrder = [i18n.global.t('callsDetails.CompleteTranscript')];
      const seenChapters = new Set();
      for (const segment of this.processedTranscription) {
        if (!seenChapters.has(segment.chapter)) {
          chapterOrder.push(segment.chapter);
          seenChapters.add(segment.chapter);
        }
      }
      return chapterOrder;
    },

    processedTranscription() {
      if (!this.transcript || !this.transcript.transcription) {
        return [];
      }
      return this.transcript.transcription.map(segment => {
        return {
          ...segment,
          words: segment.words.map(word => ({
            ...word,
            display: word.word,
            isModerateConfidence: word.confidence >= 0.3 && word.confidence < 0.7
          }))
        };
      });
    }
  }
};
</script>

<style scoped>
.call-details-line {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: #9197B3;
}

.scorecard-title {
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.scorecard-score {
  margin-left: 10px;
}

.shared-window-space {
  display: flex;
  justify-content: space-around;
  margin-right: 20px;
}

.charts-gallery {
  flex: 1;
  height: 300px;
}

.coachingplaceholder {
  flex: 1;
}

.related-calls {
  width: 300px;
  margin: 10px;
}

.related-call-container {
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
}


.placeholdertext {
  margin: 20px;
}

.chartcard {
  flex: 1;
  height: 250px;
  max-width: 200px;
  margin: 20px;
  border: 2px #F2F2F7 solid;
  padding: 10px;
  border-radius: 4px;
}

.chart-wrapper {
  height: 200px;
  width: 180px;
  padding: 15px;
}

.confirm-reject-btn {
  max-width: 30px;
  max-height: 30px;
  margin: 5px;
}

.button-group {
  margin-left: 30px;
  margin-top: 25px;
}

.topic-item {
  padding: 15px;
}

.selected-chapter {
  background-color: #5932EA;
  color: white;
  border-radius: 4px;
}


.tabgroup {
  width: 95%;
  margin-left: 30px;
  border: solid 1px #F2F2F7;
  border-radius: 4px;
}

.tab--active {
  color: white !important;
  background-color: #5932EA !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-radius: 4px;
}

.tab--inactive {
  color: #5932EA !important;
  background-color: white !important;
  border-radius: 4px;
}

.flex-select {
  width: auto;
}

.tag.success {
  background-color: #D3FFE7;
  color: #00AC4F;
  border-radius: 4px;
  margin: 5px;
  padding: 5px;
}

.tag.failure {
  background-color: #FCF2E6;
  color: #D57200;
  border-radius: 4px;
  margin: 5px;
  padding: 5px;
}

.topic-item {
  padding: 15px;
}

.ner-accordion-container {
  padding: 30px;
  overflow-y: auto;
}

.secondary-btn {
  color: #5932EA;
  border: 1px solid #5932EA;
  padding: 10px;
}

.view-container {
  display: flex;
  justify-content: top;
  flex-direction: column;
  align-items: left;
  min-height: 600px;
  margin: 20px;
  padding: 20px;
}

.transcript-table {
  width: 100%;
  border-collapse: collapse;
}

.transcript-table th,
.transcript-table td {
  padding: 5px;
}

.transcript-table th,
.transcript-table td {
  text-align: left;
}

.transcript-word {
  cursor: pointer;
  font-size: 14px;
}

.transcript-word:hover {
  text-decoration: underline;
  color: #FFFFFF;
  background-color: #5932EA;
  font-weight: bold;
}

.chapter-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 15px;
}

.chapter-tab {
  padding: 10px 15px;
  cursor: pointer;
  border: solid 2px #F2F2F7;
  border-radius: 4px;
  background-color: white;
  color: #5932EA;
}

.chapters {
  text-align: left;
  width: 20%;
  border-radius: 4px;
  border: solid 2px #F2F2F7;
  margin: 15px;
}

.chapter {
  padding: 15px;
  cursor: pointer;
  border-radius: 4px;
}

.spring-animation {
  display: flex;
  max-width: 50px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  animation: spring-spin 2s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@keyframes spring-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

[v-cloak] {
  display: none;
}

.enlarged-audio-player {
  width: 100%;
  height: 40px;
}

.tabbed-section {
  margin-left: 30px;
}


.h3-style {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.5em;
}


.audio-container {
  position: sticky;
  margin: 20px;
  bottom: 0px;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: calc(100% - 40px);
  /* Subtract the left and right margins */
}

.full-width-audio {
  width: 100%;
}

.full-width-audio::-webkit-media-controls-panel {
  height: 50px;
  /* Adjust this value as needed */
}

.audio-player-wrapper {
  width: 100%;
  margin-bottom: 10px;
}

.audio-filename-wrapper {
  width: 100%;
  text-align: center;
}

.highlights-list {
  list-style-type: none;
  padding: 0;
}

.highlight-item {
  color: gray;
  margin-bottom: 8px;
  padding-left: 30px;
}

.highlight-emoji {
  margin-right: 8px;
}

.highlight-time {
  cursor: pointer;
  color: #5932EA;
  margin-right: 8px;
}

.highlight-time:hover {
  text-decoration: underline;
}

.scorecard-title {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 10px;
}

.scorecard-score {
  margin-left: 5px;
  padding: 2px 5px;
  border-radius: 3px;
  font-weight: bold;
  white-space: nowrap;
}

.chapter-score {
  margin-left: 5px;
  white-space: nowrap;
  padding: 2px 5px;
  border-radius: 3px;
  font-weight: bold;
}

.score-red {
  color: #FF0000;
  background-color: #FFEEEE;
}

.score-orange {
  color: #FFA500;
  background-color: #FFF5E6;
}

.score-green {
  color: #008000;
  background-color: #E6FFE6;
}

.selected-chapter {
  background-color: #e0e0e0;
  color: purple;
}

.selected-score {
  background-color: white !important;
}
</style>