<template>
  <div class="view-container" v-if="customers.length > 0">
    <v-card class="card rounded-xl"
      style="width: 100%; margin: 0 auto; padding: 25px; box-sizing: border-box; display: flex; flex-direction: column; align-items: left; justify-content: center; gap: 15px;">
      <v-breadcrumbs style="margin-left: 15px;">
        <v-breadcrumbs-item to="/:tenant/customers" color="primary">{{ $t('customerDetails.customer')
          }}</v-breadcrumbs-item>
        >
        <v-breadcrumbs-item>{{ customers[0].first_name }} {{ customers[0].last_name }}</v-breadcrumbs-item>
      </v-breadcrumbs>
      <h1 style="text-align: left; margin-bottom:15px;margin-left: 30px">{{ $t('customerDetails.customer') }}</h1>
      <v-form style="max-width:350px; margin-left: 30px">
        <v-text-field :label="$t('customerDetails.lastName')" variant="solo" v-model="customers[0].first_name"
          @blur="updateCustomer('first_name', customers[0].first_name)"></v-text-field>
        <v-text-field :label="$t('customerDetails.firstName')" v-model="customers[0].last_name" variant="solo"
          @blur="updateCustomer('last_name', customers[0].last_name)"></v-text-field>
      </v-form>
    </v-card>

  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { supabase, userState } from "../supabase";
import { useRoute } from 'vue-router';

export default {
  setup() {
    const id = ref(null);
    const customers = ref([]);
    const route = useRoute();

    onMounted(async () => {
      id.value = route.params.id;
      const user = userState.currentUser;
      if (!user) throw new Error('User not found');

      // Retrieve tenant id
      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [user.id]);
      if (orgError) {
        console.error('Organisations query error:', orgError); // Log any query errors
        throw orgError;
      }
      const organisationIds = organisations.map(org => org.id);

      try {
        const { data: fetchedCustomers, error: customerError } = await supabase
          .from('customers')
          .select('id, first_name, last_name')
          .in('organisation', organisationIds) // new for multi-tenancy
          .eq('id', id.value);
        if (customerError) throw customerError;
        customers.value = fetchedCustomers;
      } catch (error) {
        console.error(error);
      }
    });
    const updateCustomer = async (field, value) => {
      try {
        const { error } = await supabase
          .from('customers')
          .update({ [field]: value })
          .eq('id', id.value);
        if (error) throw error;
      } catch (error) {
        console.error('Failed to update customer:', error);
      }
    };
    return {
      customers,
      updateCustomer
    };
  },
}
</script>

<style scoped>
/* Component styles go here */
</style>