<template>
    <v-card>
        <v-container>
            <ExtendedTemplateCard :title="cardInfo.title" :price="cardInfo.price" :description="cardInfo.description"
                :authorName="cardInfo.authorName" :authorTitle="cardInfo.authorTitle" :ctaText="cardInfo.ctaText"
                :tags="cardInfo.tags" :header1="header" :text1="formattedIntroParagraph" :price1="cardInfo.price1"
                :ctaText1="cardInfo.ctaText1" :header2="secondHeader" :text2="formattedDetailsParagraph"
                :price2="cardInfo.price2" :ctaText2="cardInfo.ctaText2" :ctaLink2="cardInfo.ctaLink2"
                @navigateToLink="navigateToLink">
            </ExtendedTemplateCard>
        </v-container>
    </v-card>
</template>

<script>
import ExtendedTemplateCard from '@/components/ExtendedTemplateCard.vue';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
const details = {
    jahresgespraech: {
        header: 'KI-Coaching und Datenextraktion',
        introParagraph: [
            'KI-Coaching Coaching für jedes Gespräch anhand der Lieblingsmakler-Beratungslogik.',
            'Coaching und Datenextraktion für alle 20 Gesprächskapitel, 15 Cross-Selling-Brücken und 47 Talking Points.',
            'KI-Insights und passgenaue Video-Impulse aus dem Seminar.',
            'Mitschrieb bei: Kundenempfehlungen, Stammdaten, Bewertungen, Bestandsverträgen.'
        ],
        secondHeader: 'Seminar-Aufzeichnung',
        detailsParagraph: [
            'Erfahre in diesem praxisorientierten 1-Tages Seminar, wie du:',
            'Alle ertragreichen Themen beim Kunden richtig ansprichst',
            'sämtliche Brücken(ca. 15 Stück) nutzt, um alle Themen abzuhandeln (Sach, KV, LV, Fonds, selbst die "geliebte" PFLEGEVERSICHERUNG)',
            'Empfehlungen Deiner Kunden erhältst',
            'Bewertungen sammelst (Währung der Zukunft)',
            'nichts mehr vergisst und Geschäft liegen lässt.',
            'mittels einer Checkliste allen wichtigen Themen Deines Kunden Deine Aufmerksamkeit schenkst usw.',
            'Bei Unzufriedenheit erhältst Du kulant Dein Geld zurück.'
        ],
        cardInfo: {
            title: 'Jahresgespräch',
            price: '',
            description: 'Generiere über 20.000€ Bewertungssumme pro Termin, betreue auasschließlich Vollmandate (7 Verträge p.P.) und enthafte dich mit Robert’s optimierten Beratungsansatz.',
            authorName: 'Robert Peukert',
            authorTitle: 'Geschäftsführer Lieblingsmakler',
            ctaText: 'KI-Coaching buchen',
            tags: ['SACH', 'IMMO', 'ALTERSVORSORGE'],
            price1: '25€ / Monat',
            ctaText1: 'Beratungsansatz buchen',
            price2: '397€ zzgl. MwSt. (lebenslanger Zugriff)',
            ctaText2: 'Seminaraufzeichnung kaufen',
            ctaLink2: 'https://www.digistore24.com/redir/488761/UseToday/'
        }
    },
    altersvorsorgegespraech: {
        header: 'KI-Coaching',
        introParagraph: [
            'KI-Coaching Coaching für jedes Gespräch anhand Robert’s Beratungslogik.',
            'Scorecard mit 22 Kapiteln und 49 Talking Points.',
            'Coaching-Impulse mit Videoausschnitten aus Roberts Video-Seminar.'
        ],
        secondHeader: '3-Tages Seminaraufzeichnung mit den Highlights',
        detailsParagraph: [
            'Mindset-Shift: Kaufen lassen und nie wieder verkaufen - vom Bittsteller zum Chancengeber!',
            'Hands-on Verkaufsausbildung inkl. tiefer Kundenergründung',
            '28-Schritt holistischer Beratungsprozess',
            'Fachausbildung für Vorsorge, Rentenplanung',
        ],
        cardInfo: {
            title: 'Die perfekte Altersvorsorgeberatung',
            price: '',
            description: 'Nutze diesen systemischen, über Jahre erprobten Beratungsprozess, um innerhalb von 12 Monaten spielerisch 3 Mio. Bewertungssumme zu verkaufen.',
            authorName: 'Robert Peukert',
            authorTitle: 'Geschäftsführer Lieblingsmakler',
            ctaText: 'KI-Coaching buchen',
            tags: ['Altersvorsorge', 'Immobilien'],
            price1: '25€ / Monat',
            ctaText1: 'Scorecard buchen',
            price2: '997€ zzgl. MwSt. (lebenslanger Zugriff)',
            ctaText2: 'Seminaraufzeichnung kaufen',
            ctaLink2: 'https://www.digistore24.com/redir/488761/UseToday/'
        }
    }
};

export default {
    name: 'ConversationTypeDetails',
    components: {
        ExtendedTemplateCard
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const type = computed(() => route.params.type);
        const detailData = computed(() => details[type.value]);

        const header = computed(() => detailData.value.header);
        const introParagraph = computed(() => detailData.value.introParagraph);
        const secondHeader = computed(() => detailData.value.secondHeader);
        const detailsParagraph = computed(() => detailData.value.detailsParagraph);

        const cardInfo = computed(() =>
            detailData.value.cardInfo ? detailData.value.cardInfo : {}
        );

        const formattedIntroParagraph = computed(() => introParagraph.value.map(item => `<li>${item}</li>`).join(''));
        const formattedDetailsParagraph = computed(() => detailsParagraph.value.map(item => `<li>${item}</li>`).join(''));

        const navigateToLink = (link) => {
            if (link.startsWith("http")) {
                window.location.href = link;
            } else {
                router.push(link);
            }
        };

        return {
            header,
            introParagraph,
            formattedIntroParagraph,
            secondHeader,
            detailsParagraph,
            formattedDetailsParagraph,
            cardInfo,
            navigateToLink
        };
    }
};
</script>

<style scoped>
p {
    margin-bottom: 20px;
}

.detail-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.cta-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.price {
    margin-bottom: 10px;
}
</style>