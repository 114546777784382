import { createI18n } from 'vue-i18n';
import en from './languages/en.json';
import de from './languages/de.json';
import pt from './languages/pt.json';
import dk from './languages/dk.json';
import axios from 'axios';

const messages = {
  en,
  de,
  pt,
  dk
};

try {
  await axios.post(
    `${process.env.VUE_APP_BACKEND_URL}/api/send_language`,
    { value: localStorage.getItem('selectedLanguage') || 'de' },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
} catch (error) {
  console.error('Error sending language:', error);

}

const i18n = createI18n({
  locale: localStorage.getItem('selectedLanguage') || 'de',
  messages
});

export default i18n;