<template>
    <v-card class="mt-6">
        <v-card-title>{{ $t('dashboardAnalytics.title') }}</v-card-title>
        <v-card-text>
            <div class="button-groups">
                <div class="time-frame-group">
                    <v-btn @click="setTimeFrame('today')" :color="timeFrame === 'today' ? 'primary' : ''">{{
                        $t('dashboardAnalytics.today')
                        }}</v-btn>
                    <v-btn @click="setTimeFrame('week')" :color="timeFrame === 'week' ? 'primary' : ''">{{
                        $t('dashboardAnalytics.week')
                        }}</v-btn>
                    <v-btn @click="setTimeFrame('month')" :color="timeFrame === 'month' ? 'primary' : ''">{{
                        $t('dashboardAnalytics.month')
                        }}</v-btn>
                </div>
                <div class="analysis-type-group">
                    <v-btn @click="setAnalysisType('berater')" :color="analysisType === 'berater' ? 'primary' : ''">{{
                        $t('dashboardAnalytics.advisor') }}</v-btn>
                    <v-btn @click="setAnalysisType('gespraechstyp')"
                        :color="analysisType === 'gespraechstyp' ? 'primary' : ''">{{
                            $t('dashboardAnalytics.conversationType') }}</v-btn>

                    <!-- Dropdown for Aggregationstyp -->
                    <v-select v-model="selectedAggregationType" :items="aggregationTypes"
                        :label="$t('dashboardAnalytics.aggregationType')" outlined dense solo />
                </div>
            </div>

            <!-- Multi-select for Berater -->
            <v-select v-if="analysisType === 'berater'" v-model="selectedAdvisors" :items="advisorsList"
                item-title="name" item-value="id" :label="$t('dashboardAnalytics.choseAdvisor')" multiple chips
                small-chips />

            <!-- Multi-select for Gesprächstyp -->
            <v-select v-if="analysisType === 'gespraechstyp'" v-model="selectedScorecards" :items="scorecardList"
                item-title="$t('dashboardAnalytics.name')" item-value="id"
                :label="$t('dashboardAnalytics.choseConversationType')" multiple chips small-chips />

            <v-chart ref="chartRef" class="chart" :option="chartOption" autoresize />
        </v-card-text>
    </v-card>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, LegendComponent } from 'echarts/components';
import { supabase, userState } from "../supabase";
import { useRouter, useRoute } from 'vue-router';

use([CanvasRenderer, BarChart, GridComponent, TooltipComponent, LegendComponent]);

const selectedAdvisors = ref([]);
const advisorsList = ref([]);
const selectedScorecards = ref([]);
const timeFrame = ref('today');
const analysisType = ref('call-score');
const callsData = ref([]);

const aggregationTypes = ref(['Call-Score', 'Call-Count', 'Erwarteter Umsatz']);
const selectedAggregationType = ref('Call-Score');

const router = useRouter();
const route = useRoute();
const tenant = computed(() => route.params.tenant);

const setTimeFrame = (frame) => {
    timeFrame.value = frame;
};

const setAnalysisType = (type) => {
    analysisType.value = type;
};

const chartRef = ref(null);

onMounted(async () => {
    await fetchCalls();
    if (chartRef.value) {
        chartRef.value.setOption(chartOption.value);
        chartRef.value.chart.on('click', (params) => {
            if (params.componentType === 'series') {
                handleBarClick(params);
            }
        });
    }
});

const scorecardList = ref([]);

const fetchCalls = async () => {
    const user = userState.currentUser;
    if (!user) {
        console.error('User not found');
        return;
    }

    // Step 1: Query the advisors table for the current user
    const { data: advisor, error: advisorError } = await supabase
        .from("advisors")
        .select("id, direct_reports")
        .eq('user_id', user.id)
        .single();

    if (advisorError) {
        console.error('Error fetching advisor:', advisorError);
        return;
    }

    let userIds = [user.id];
    advisorsList.value = [
        { id: 'self', name: 'Ich' },
        { id: 'all', name: 'Alle' }
    ];

    if (advisor.direct_reports && advisor.direct_reports.length > 0) {
        // Fetch team members (direct reports)
        const { data: teamMembers, error: teamError } = await supabase
            .from("advisors")
            .select("id, user_id, first_name, last_name")
            .in('id', advisor.direct_reports);

        if (teamError) {
            console.error('Error fetching team members:', teamError);
        } else {
            advisorsList.value.push(...teamMembers.map(member => ({
                id: member.user_id,
                name: `${member.first_name} ${member.last_name}`
            })));
            userIds = [...userIds, ...teamMembers.map(member => member.user_id)];
        }
    }

    const { data: calls, error: callsError } = await supabase
        .from("calls")
        .select("*")
        .in('user_id', userIds);

    if (callsError) {
        console.error('Error fetching calls:', callsError);
        return;
    }

    callsData.value = calls;

    // Step 2: Extract unique scorecard_ids from calls
    const scorecardIds = [...new Set(calls.map(call => call.scorecard_id).filter(id => id !== null && id !== undefined))];

    // Step 3: Query the scorecards table for names
    const { data: scorecards, error: scorecardsError } = await supabase
        .from("scorecards")
        .select("id, name")
        .in('id', scorecardIds);

    if (scorecardsError) {
        console.error('Error fetching scorecards:', scorecardsError);
        return;
    }

    // Update scorecardList with fetched scorecard names only
    scorecardList.value = scorecards.map(scorecard => ({
        id: scorecard.id,
        name: scorecard.name
    }));
};

const filteredCalls = computed(() => {
    let filtered = callsData.value;
    // If no advisors are selected, return all calls
    if (selectedAdvisors.value.length === 0 || selectedAdvisors.value.includes('all')) {
        // No need to filter by advisors, return all calls

    } else {
        // Filter by selected advisors
        filtered = filtered.filter(call =>
            selectedAdvisors.value.includes(call.user_id)
        );
    }

    // If no scorecards are selected, return all calls
    if (selectedScorecards.value.length !== 0) {
        // Filter by selected scorecards
        filtered = filtered.filter(call =>
            selectedScorecards.value.includes(call.scorecard_id)
        );
    }
    return filtered;
});



const processData = (data, timeFrame) => {
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1); // Include end of today

    // Calculate 3 days before and 3 days after the current date
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - 3);
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(now);
    endOfWeek.setDate(now.getDate() + 3);
    endOfWeek.setHours(23, 59, 59, 999);

    // Calculate 15 days before and 15 days after the current date
    const startOfMonth = new Date(now);
    startOfMonth.setDate(now.getDate() - 15);
    startOfMonth.setHours(0, 0, 0, 0);
    const endOfMonth = new Date(now);
    endOfMonth.setDate(now.getDate() + 15);
    endOfMonth.setHours(23, 59, 59, 999);

    return data.filter(call => {
        const callDate = new Date(call.start);
        switch (timeFrame) {
            case 'today':
                // Include calls from start of day and future calls within today
                return callDate >= startOfDay && callDate <= endOfDay;
            case 'week':
                // Include calls from 3 days in the past to 3 days in the future
                return callDate >= startOfWeek && callDate <= endOfWeek;
            case 'month':
                // Include calls from 15 days in the past to 15 days in the future
                return callDate >= startOfMonth && callDate <= endOfMonth;
        }
    });
};


const getWeekDates = () => {
    const today = new Date();
    const weekDates = [];
    for (let i = -3; i < 4; i++) {
        const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() + i);
        weekDates.push(date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit' }));
    }
    return weekDates;
};

const getMonthWeeks = () => {
    const today = new Date();
    const weeks = [];
    for (let i = -1; i < 3; i++) {
        const weekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (i * 7));
        weeks.push(`W${getWeekNumber(weekStart)}`);
    }
    return weeks;
};

const getWeekNumber = (date) => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
};

watch([timeFrame, filteredCalls, selectedAggregationType], () => {
    if (chartRef.value) {
        chartRef.value.clear();
        chartRef.value.setOption(chartOption.value, true);
        chartRef.value.chart.on('click', (params) => {
            if (params.componentType === 'series') {
                handleBarClick(params);
            }
        });
    }
});

watch(timeFrame, (newValue) => {
    // Reset the x-axis regardless of filtered call data
    switch (newValue) {
        case 'today':
            // Always set xAxisData for today, even if no calls match
            chartOption.value.xAxis.data = ['0-4', '4-8', '8-12', '12-16', '16-20', '20-24'];
            break;
        case 'week':
            // Always set xAxisData for the week
            chartOption.value.xAxis.data = getWeekDates();
            break;
        case 'month':
            // Always set xAxisData for the month (weeks)
            chartOption.value.xAxis.data = getMonthWeeks();
            break;
    }

    // Proceed with updating the chart
    if (chartRef.value) {
        chartRef.value.clear(); // Clear the chart before updating
        chartRef.value.setOption(chartOption.value, true); // Update chart options
    }
});



const conversationTypeColors = {
    'Lieblingsmakler Scorecard': '#5932EA', // Purple (Darker Shade)
    'Ersttermin': '#7453EE',               // Slightly Lighter Purple
    'AV-Beratung': '#8F75F1',              // Lighter Purple
    'AV-Beratung: Käpsele': '#AA97F5',     // Softer Purple
    'TestMichael': '#6D5CB6',              // Light Lavender
    'T2': '#7863D8',                       // Very Light Lavender
    'Neue Scorecard': '#5437C0',           // Near White Lavender
    'T1': '#674CDD',                       // Additional Shade of Purple
    'T3': '#44396A',                       // Mid-tone Purple
    'T4: Check-up': '#554E71',             // Slightly Lighter
    'Jahresgespräch': '#352E54', // Light Purple
    'T1 Immo': '#948BCC',                  // Very Light Purple
    'T2 Immo': '#1C0865',                  // Almost White Lavender
};

const handleBarClick = (params) => {
    if (!params.seriesName || !params.name) {
        console.warn('Missing required params:', { seriesName: params.seriesName, name: params.name });
        return;
    }

    let startDate, endDate;
    switch (timeFrame.value) {
        case 'today': {
            const [startHour] = params.name.split('-');
            const today = new Date();
            today.setHours(parseInt(startHour), 0, 0, 0);
            startDate = today.toISOString().split('T')[0];
            break;
        }
        case 'week': {
            const cleanName = params.name.replace(/\.$/, '');
            const [day, month] = cleanName.split('.');
            const paddedDay = day.padStart(2, '0');
            const paddedMonth = month.padStart(2, '0');
            const currentYear = new Date().getFullYear();
            startDate = `${currentYear}-${paddedMonth}-${paddedDay}`;
            break;
        }
        case 'month': {
            const weekNum = parseInt(params.name.substring(1));
            const today = new Date();
            const targetDate = new Date(today);
            targetDate.setDate(today.getDate() + ((weekNum - getWeekNumber(today)) * 7));
            startDate = targetDate.toISOString().split('T')[0];

            // Set end date to 7 days after start for month view
            const endDateObj = new Date(targetDate);
            endDateObj.setDate(targetDate.getDate() + 7);
            endDate = endDateObj.toISOString().split('T')[0];
            break;
        }
    }

    const routeConfig = {
        path: `/${tenant.value}/calls`,
        query: timeFrame.value === 'month' ? {
            'start_date>=': startDate,
            'start_date<=': endDate,
            scorecard: params.seriesName
        } : {
            start_date: startDate,
            scorecard: params.seriesName
        }
    };

    router.push(routeConfig).catch(err => {
        console.error('Navigation failed:', err);
    });
};

const chartOption = computed(() => {
    const filteredData = processData(filteredCalls.value, timeFrame.value);
    const conversationTypes = scorecardList.value.map(scorecard => scorecard.name);

    // Create a series for each conversation type
    const seriesData = conversationTypes.map(type => ({
        name: type,
        type: 'bar',
        stack: 'total',
        data: []
    }));

    // Initialize xAxisData based on the selected time frame
    let xAxisData = [];
    switch (timeFrame.value) {
        case 'today': {
            xAxisData = ['0-4', '4-8', '8-12', '12-16', '16-20', '20-24'];
            seriesData.forEach(series => series.data = Array(xAxisData.length).fill(0));
            break;
        }
        case 'week': {
            xAxisData = getWeekDates();
            seriesData.forEach(series => series.data = Array(xAxisData.length).fill(0));
            break;
        }
        case 'month': {
            xAxisData = getMonthWeeks();
            seriesData.forEach(series => series.data = Array(xAxisData.length).fill(0));
            break;
        }
    }
    // Populate series data based on filtered calls
    filteredData.forEach(call => {
        let index;
        switch (timeFrame.value) {
            case 'today': {
                const hour = new Date(call.start).getHours();
                index = Math.floor(hour / 4);
                break;
            }
            case 'week': {
                const callDate = new Date(call.start);
                const today = new Date();
                callDate.setHours(0, 0, 0, 0);
                today.setHours(0, 0, 0, 0);
                const diffDays = Math.floor((callDate - today) / (24 * 60 * 60 * 1000));
                index = diffDays + 3;
                break;
            }
            case 'month': {
                const currentWeekNumber = getWeekNumber(new Date()); // Get the current week number
                const callDate = new Date(call.start);
                const callWeekNumber = getWeekNumber(callDate); // Get the week number for the call
                const weekDifference = callWeekNumber - currentWeekNumber;

                // Assign the call to the correct index based on the weekDifference
                if (weekDifference === -1) {
                    index = 0; // One week before the current week
                } else if (weekDifference === 0) {
                    index = 1; // Current week
                } else if (weekDifference === 1) {
                    index = 2; // One week after the current week
                } else if (weekDifference === 2) {
                    index = 3; // Two weeks after the current week
                }
                break; // No need to redeclare `index`
            }
        }

        const conversationType = scorecardList.value.find(scorecard => scorecard.id === call.scorecard_id)?.name;
        const seriesIndex = conversationTypes.indexOf(conversationType);

        // If seriesIndex is -1, skip to the next iteration
        if (seriesIndex === -1) {
            return; // Skip the rest of this loop and move to the next `call`
        }

        if (index >= 0 && index < xAxisData.length) {
            seriesData[seriesIndex].data[index]++;
            seriesData[seriesIndex].itemStyle = {
                color: conversationTypeColors[conversationType] || '#000000' // Default color if not found
            };
        }
    });

    return {
        xAxis: {
            type: 'category',
            data: xAxisData, // Ensure xAxisData is updated
        },
        yAxis: {
            type: 'value',
        },
        series: seriesData.map(series => ({
            ...series,
            emphasis: {
                focus: 'series'
            },
            cursor: 'pointer',
            silent: false // Ensure events aren't silenced
        })),
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: Object.keys(conversationTypeColors), // Use all conversation types for legend
            textStyle: {
                fontSize: 12, // Customize text size if needed
                color: (name) => conversationTypeColors[name] || '#000000' // Apply color from conversationTypeColors
            }
        }
    };
});

</script>


<style scoped>
.chart {
    height: 400px;
    width: 100%;
}

.button-groups {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10%;
    /* Add padding to bring groups closer to the center */
}

.time-frame-group,
.analysis-type-group {
    display: flex;
    gap: 10px;
}

/* Add these new styles */
.time-frame-group {
    margin-right: auto;
    /* Push to the left */
}

.analysis-type-group {
    margin-left: auto;
    /* Push to the right */
}
</style>