<template>
  <v-card style="margin: 20px; padding: 20px" class="card rounded-xl">
    <v-breadcrumbs style="margin-left: 15px;">
      <v-breadcrumbs-item to="/:tenant/settings" color="primary">{{ $t("settingsConversationTypes.settings")
        }}</v-breadcrumbs-item> >
      <v-breadcrumbs-item>{{ $t("settingsConversationTypes.coaching") }}</v-breadcrumbs-item>
    </v-breadcrumbs>

    <h1 style="text-align: left; margin-left: 30px;padding-bottom: 15px">{{ $t("settingsConversationTypes.title") }}
    </h1>

    <v-alert type="info" color="backgroundsecondary" style="text-align: left; margin-left: 25px; margin-bottom: 30px;">
      {{ $t("settingsConversationTypes.description") }}
    </v-alert>
    <div v-if="$i18n.locale === 'de'" class="min-h-screen bg-gray-100 p-8">
      <div class="card-grid">
        <div class="card-container">
          <TemplateCard :title="Jahresgespräch" price="25€/Monat" :description="$t('templateCard.description1')"
            :authorName="$t('templateCard.authorName1')" :authorTitle="$t('templateCard.authorTitle1')"
            :ctaText="$t('templateCard.ctaText1')" :tags="['ALTERSVORSORGE', 'IMMO', 'SACH']"
            :link="$t('templateCard.link1')" />
        </div>
        <div class="card-container">
          <TemplateCard :title="Altersvorsorge" price="25€/Monat" :description="$t('templateCard.description2')"
            :authorName="$t('templateCard.authorName2')" :authorTitle="$t('templateCard.authorTitle2')"
            :ctaText="$t('templateCard.ctaText2')" :tags="['ALTERSVORSORGE', 'IMMO']"
            :link="$t('templateCard.link2')" />
        </div>
      </div>
    </div>


    <div style=" display: flex; margin-left: 30px; text-align: left;">
      <div style="display: flex; flex-direction: column; max-width: 300px; text-align: left;">
        <h3 style="padding-bottom: 10px; width: 300px; min-height: 40px;">{{
          $t("settingsConversationTypes.yourCallTypes") }}</h3>
        <v-btn variant="tonal" color="primary" @click="addNewField"
          style="margin-bottom: 10px; margin-right: 20px; margin-left: 0;">{{ $t("settingsConversationTypes.add")
          }}</v-btn>
      </div>

      <div style="display: flex; flex-direction: column; max-width: 300px; margin-left: 30px;">
        <h3 v-if="(isSaved || isFetched)" style="padding-bottom: 10px; width: 300px; min-height: 80px;">{{
          $t("settingsConversationTypes.yourScorecards") }}
        </h3>
      </div>
    </div>

    <div v-for="(field, index) in fields" :key="index" class="field-container">
      <v-text-field variant="solo" style="max-width: 300px;" v-model="fields[index].name"
        placeholder="z.b. Jahresgespräch, Vorsorgeberatung, ...">
      </v-text-field>
      <!-- Trash can icon for deleting a field -->
      <v-btn icon @click="confirmDelete(index)" class="delete-btn">
        <v-icon flat color="#9197B3">mdi-delete</v-icon>
      </v-btn>
      <v-btn :disabled="!(isSaved || isFetched)" v-if="!fields[index].associated_scorecard" color="#9197B3"
        style="margin-left: 20px; color:white" @click="chooseScorecard(index)">
        {{ $t("settingsConversationTypes.selectScorecard") }}
      </v-btn>
      <!-- btn to edit associated scorecard -->
      <v-btn v-if="fields[index].associated_scorecard"
        :to="`/${tenant}/scorecards/${fields[index].associated_scorecard}`"
        style="width: 300px; text-align: left; color: #9197b3; margin-left: 20px" text>
        <v-icon left>
          mdi-pencil
        </v-icon>

        <span class="truncate">{{ getScorecardNameById(fields[index].associated_scorecard) }}</span>
      </v-btn>

      <v-btn variant="text" color="#9197B3" style="margin-left: 20px;" v-if="fields[index].associated_scorecard"
        @click="chooseScorecard(index)">


        <!-- Other Scorecard -->
        <v-icon left>mdi-pencil</v-icon>
        {{ $t("settingsConversationTypes.otherScorecard") }}
      </v-btn>
    </div>
    <div style="margin-left: 15px; text-align: left;">
      <v-btn color="primary" @click="saveCallTypes" style="margin: 20px;">{{ $t("settingsConversationTypes.save")
        }}</v-btn>
    </div>
  </v-card>

  <v-dialog v-model="deleteDialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="text-h5">{{ $t("settingsConversationTypes.deleteCallType") }}</v-card-title>
      <v-card-text>{{ $t("settingsConversationTypes.deleteCallTypeConfirm") }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="deleteDialog = false">{{ $t("settingsConversationTypes.cancel")
          }}</v-btn>
        <v-btn color="blue darken-1" text @click="deleteField">{{ $t("settingsConversationTypes.confirm") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, onMounted, provide } from 'vue';
import TemplateCard from '@/components/TemplateCard.vue'
import { supabase } from '../supabase';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useUser } from '../useUser';

export default {
  components: {
    TemplateCard
  },
  setup() {
    const router = useRouter();
    const { user } = useUser();
    const fields = ref([{ name: '', associated_scorecard: null }]);
    const selectedField = ref('');
    const isSaved = ref(false);
    const isFetched = ref(false);
    const scorecards = ref([]);
    const route = useRoute();
    const tenant = route.params.tenant;

    const deleteDialog = ref(false);
    const deleteIndex = ref(null);

    const confirmDelete = (index) => {
      deleteIndex.value = index;
      deleteDialog.value = true;
    }

    provide('selectedField', selectedField);


    onMounted(async () => {
      const userId = user.value.id;

      // Get organization's ID associated with the user
      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [userId]);
      if (orgError || !organisations.length) {
        console.error('Error fetching organisation:', orgError);
        return;
      }
      const organisationId = organisations[0].id;

      // Fetch conversation types for the entire organization
      const { data, error } = await supabase
        .from('conversationtypes')
        .select('name, associated_scorecard')
        .eq('associated_organisation_id', organisationId);
      if (error) {
        console.error('Error fetching conversation types:', error);
      } else {
        fields.value = data.map(item => ({ name: item.name, associated_scorecard: item.associated_scorecard }));
        isFetched.value = true;
      }

      // Fetch scorecards for the entire organization
      const { data: scorecardsData, error: scorecardsError } = await supabase
        .from('scorecards')
        .select('*')
        .eq('organisation', organisationId);
      if (scorecardsError) {
        console.error('Error fetching scorecards:', scorecardsError);
      } else {
        scorecards.value = scorecardsData;
      }
    });

    const getScorecardNameById = (id) => {
      const scorecard = scorecards.value.find(scorecard => scorecard.id === id);
      return scorecard ? scorecard.name : '';
    };


    const addNewField = () => {
      fields.value.push({ name: '', associated_scorecard: null });
    };

    const saveCallTypes = async () => {
      const userId = user.value.id;

      // Get organization's ID associated with the user
      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [userId]);
      if (orgError || !organisations.length) {
        console.error('Error fetching organisation:', orgError);
        return;
      }
      const organisationId = organisations[0].id;

      // Check if any field has a non-empty name
      const hasPopulatedField = fields.value.some(field => field.name.trim() !== '');
      if (!hasPopulatedField) {
        console.error('No field has been populated');
        return;
      }

      // Fetch existing conversation types for the organization
      const { data: existingCallTypes, error: fetchError } = await supabase
        .from('conversationtypes')
        .select('name')
        .eq('associated_organisation_id', organisationId);
      if (fetchError) {
        console.error('Error fetching conversation types:', fetchError);
      }

      // Filter out the conversation types that are already in the database
      const existingCallTypeNames = existingCallTypes.map(callType => callType.name);
      const newCallTypes = fields.value.filter(field => !existingCallTypeNames.includes(field.name) && field.name.trim() !== '');

      // Save the new conversation types
      for (const field of newCallTypes) {
        const { error } = await supabase
          .from('conversationtypes')
          .insert([{ name: field.name, associated_organisation_id: organisationId }]);
        if (error) {
          console.error('Error saving conversation type:', error);
        }
      }
      isSaved.value = true;
    };

    const chooseScorecard = (index) => {
      selectedField.value = fields.value[index];
      router.push({ name: 'scorecards', query: { selectedFieldName: selectedField.value.name } });
    };

    const deleteField = async () => {
      if (deleteIndex.value !== null) {
        const userId = user.value.id;

        // Get organization's ID associated with the user
        const { data: organisations, error: orgError } = await supabase
          .from('organisations')
          .select('id')
          .contains('associated_user_ids', [userId]);
        if (orgError || !organisations.length) {
          console.error('Error fetching organisation:', orgError);
          return;
        }
        const organisationId = organisations[0].id;

        const fieldToDelete = fields.value[deleteIndex.value];
        await supabase
          .from('conversationtypes')
          .delete()
          .match({ name: fieldToDelete.name, associated_organisation_id: organisationId });

        fields.value.splice(deleteIndex.value, 1);
        deleteDialog.value = false;
        deleteIndex.value = null;
      }
    };

    return {
      fields,
      addNewField,
      saveCallTypes,
      chooseScorecard,
      isSaved,
      isFetched,
      getScorecardNameById,
      tenant,
      deleteDialog,
      confirmDelete,
      deleteField,
    };
  },
};
</script>

<style scoped>
.card-grid {
  display: flex;
  gap: 1rem;
}

.scorecardtable {
  width: 100%;
  margin-left: 15px;
  margin-bottom: 30px;
}

.field-container {
  margin-left: 30px;
  display: flex;
  align-items: left;
  gap: 20px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-container {
  margin-left: 30px;
  flex: 1 1 40%;
  max-width: 40%;
}
</style>